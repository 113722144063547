import { Button, CircularProgress, Divider } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import './exam.css'
import DownloadReport from '../../assessment/newfeatures/DownlodReport';

function ScoreSummerySheet() {
    const [assessmentDetails, setassessmentDetails] = useState();
    const [questionMarks, setquestionMarks] = useState();
    const [flag, setflag] = useState(false);
    const location = useLocation();
    const AssessmentAttemptId = location?.state?.data;
    const navigate = useNavigate();

    const getScoreData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/ScoreCard/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ AssessmentAttemptId })
            });
            const result = await response.json();
            if (response.ok) {
                setassessmentDetails(result.assessmentDetails);
                setquestionMarks(result.questionMarks);
                setflag(true);
            }
        } catch (error) {
            console.error('Fetch Error:', error);
        }
    };

    useEffect(() => {
        getScoreData();
    }, []);

    const downloadPDF = () => {
        const content = document.querySelector('.scoreCard');
        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save('marksheet.pdf');
        });
    };
    const componentRef = useRef();
    const marksobtained = questionMarks?.reduce((acc, current) => acc + current.Score, 0)
    console.log(marksobtained)
    return (
        <div className="p-4 scoreCard" >


            {!flag ? (
                <div className="flex justify-center items-center h-screen">
                    <CircularProgress />
                </div>
            ) : (
                <div ref={componentRef}>
                    <div className="fixed right-8 bottom-20 touch-pinch-zoom">
                    </div>

                    <div>
                        <h1 className="text-center font-semibold font-serif text-2xl m-4">Score Card</h1>
                    </div>

                    {/* Assessment Details Section */}
                    <section className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
                        <h2 className="text-2xl font-semibold mb-4">Assessment Details</h2>
                        <div className="">
                            {assessmentDetails?.map((detail) => (
                                <div
                                    key={detail.AssessmentAttemptId}
                                    className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 assessmentDetails"
                                >
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Assessment Name</h3>
                                        <p className="text-xl font-bold text-gray-900">{detail.NameOfAssessment}</p>
                                    </div>
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Assessment Date</h3>
                                        <p className="text-xl font-bold text-gray-900">{new Date(detail.AssessmentDateTime).toLocaleDateString()}</p>
                                    </div>
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Candidate Name</h3>
                                        <p className="text-xl font-bold text-gray-900">{detail.candidateName}</p>
                                    </div>
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Candidate Email</h3>
                                        <p className="text-md font-bold text-gray-900 overflow-hidden">{detail.candidateEmail}</p>
                                    </div>
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Total Marks</h3>
                                        <p className="text-xl font-bold text-gray-900 w-10 text-center ">
                                            {detail.finalScore || marksobtained}
                                            <Divider className="w-10 font-bold " orientation="horizontal" flexItem />
                                        </p>
                                        <p className="text-xl font-bold text-gray-900 w-10 text-center ">
                                            {detail.TotalMarks}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Question Marks Section */}
                    <section className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
                        <h2 className="text-2xl font-semibold mb-4">Marks for each Question</h2>
                        <div className="overflow-x-auto rounded-md">
                            <table className="min-w-full bg-white border border-gray-200 ">
                                <thead>
                                    <tr className="bg-gray-100 border-b border-gray-200">
                                        <th className="py-3 px-4 text-left text-gray-700 font-medium">S.No</th>
                                        <th className="py-3 px-4 text-left text-gray-700 font-medium">Question</th>
                                        <th className="py-3 px-4 text-left text-gray-700 font-medium">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questionMarks?.map((question, index) => (
                                        <tr
                                            key={question.AssessmentAttemptQId}
                                            className={`border-b border-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                                        >
                                            <td className="py-3 px-4 text-gray-800">{index + 1}</td>
                                            <td className="py-3 px-4 text-gray-800 overflow-clip">{question.question || 'N/A'}</td>
                                            <td className="py-3 px-4 text-gray-800">{question.Score !== null ? question.Score : '0'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            )}

        </div>
    );
}

export default ScoreSummerySheet;
