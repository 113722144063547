import React from 'react';

const OptionsList = ({ options, isMultipleCorrect }) => {
    return (
        <div className="options-list mt-2">
            {options &&
                <ul className="list-disc pl-5">
                    {options.map(option => (
                        <div key={option.questionOption_id} className="mt-1">
                            {isMultipleCorrect ? (
                                <input
                                    type="checkbox"
                                    value={option.optionText}
                                    defaultChecked={option.isCorrect}
                                    className="mr-2"
                                />
                            ) : (
                                <input
                                    type="radio"
                                    name="option"
                                    value={option.optionText}
                                    defaultChecked={option.isCorrect}
                                    className="mr-2"
                                />
                            )}
                            {option.optionImage ? (
                                <img src={option.optionImage} alt="Option" className="inline-block mr-2" />
                            ) : (
                                option.optionText
                            )}
                        </div >
                    ))}
                </ul>}
        </div>
    );
};

export default OptionsList;
