import { AssessmentOutlined, Preview } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { act, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIconButton, DeleteIconButton, EditIconButton, TakeExamIconButtom, ViewIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import TableComp from '../reusableComp/TableComp';
import EditAssessment from './EditAssessment';
import { CustomSelect, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import { filterActionsByPermission, hasPermission } from '../reusableComp/PermissionCheck';

function ManageAssessments() {


    const permissionsNameForThisPage = {
        Add: 'Assessment-Add',
        Edit: 'Assessment-Edit',
        Delete: 'Assessment-Delete',
        View: 'Assessment-View',
        TakeExam: 'Assessment-TakeExam'
    }
    const [assessments, setAssessments] = useState([]);
    const [error, setError] = useState(null);

    const [filteredData, setfilteredData] = useState('');
    const [AssessmentSearchQuery, setAssessmentSearchQuery] = useState('');
    const [AssessmentSearchQueryWithBase, setAssessmentSearchQueryWithBase] = useState()
    const navigate = useNavigate();
    const getdata = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' }
            });
            const response = await result.json();
            setAssessments(response.message);
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        getdata();
    }, []);

    const previewAssessment = (assessmentId) => {

        navigate('/previewQuestion', { state: { data: assessmentId } })

    }
    const TakeAssessment = (assessmentId) => {
        navigate(`/assessment/`, { state: { data: assessmentId } })
    }
    const DeleteAssessment = async (assessmentId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/delete`, {
                method: 'delete',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: assessmentId })
            });
            const response = await result.json();
            if (result.ok) {
                getdata()
            }
            else {
                console.log(response)
            }
        } catch (error) {
            setError(error.message);
        }
    }

    const columns = [
        { header: 'Name', field: 'nameOfAssessment' },
        { header: 'Assessment Basis', field: 'assesmentBasis' },
        { header: 'Time (minutes)', field: 'Time' },
        { header: 'Number of Questions', field: 'numberOfQuestions' },
        { header: 'Difficulty Level', field: 'difficultyLevel' },
    ];

    const openEditComp = (item) => {
        navigate('/EditAssessment', { state: { data: item } })

    };
    const actions = [
        {
            label: 'Edit',
            handler: (item) => openEditComp(item),
            tooltip: 'Edit item',
            ButtonComponent: EditIconButton, // Use EditIconButton for edit action
            requiredPermission: permissionsNameForThisPage.Edit,

        },
        {
            label: 'Delete',
            handler: (item) => DeleteAssessment(item.assessmentId),
            tooltip: 'Delete item',
            ButtonComponent: DeleteConfirmation, // Use DeleteIconButton for delete action
            requiredPermission: permissionsNameForThisPage.Delete,

        },
        {
            label: 'Preview Assessment',
            handler: (item) => previewAssessment(item.assessmentId),
            ButtonComponent: ViewIconButton,
            tooltip: 'view only',
            requiredPermission: permissionsNameForThisPage.View,

        },
        {
            label: 'take Exam',
            handler: (item) => TakeAssessment(item.assessmentId),
            ButtonComponent: TakeExamIconButtom,
            tooltip: 'take exam',
            requiredPermission: permissionsNameForThisPage.TakeExam,

        }
    ];

    const AllowedActions = filterActionsByPermission(actions)

    useEffect(() => {
        console.log(AssessmentSearchQueryWithBase);

        const afterFilterData = assessments.filter((i) => {
            const matchesName = AssessmentSearchQuery
                ? i.nameOfAssessment.toLowerCase().includes(AssessmentSearchQuery.toLowerCase())
                : true; // No filter if the query is empty

            const matchesBase = AssessmentSearchQueryWithBase
                ? i.assesmentBasis.toLowerCase().includes(AssessmentSearchQueryWithBase)
                : true; // No filter if the query is empty

            return matchesName && matchesBase; // Return true only if both conditions are true
        });

        setfilteredData(afterFilterData);
    }, [AssessmentSearchQuery, AssessmentSearchQueryWithBase, assessments]);

    const assessmentBasisData = [

        { label: 'Job Role Based', value: 'jobrole' },
        { label: 'Competency Based', value: 'competency' },
        { label: 'Skills Based', value: 'skills' },

    ]

    return (
        <div className="container mx-auto p-4">
            {error ? (
                <p className="text-red-500">Error: {error}</p>
            ) : (

                <>

                    <Heading heading={"Manage Assessment"} />
                    <div className="mb-4 flex gap-4 justify-between items-center">
                        <div className="flex w-full gap-4">
                            {/* Assessment Name Input */}
                            <div className="md:w-1/4 lg:w-1/5 w-full">
                                <InputBox
                                    inputValue={AssessmentSearchQuery}
                                    onChange={(e) => setAssessmentSearchQuery(e.target.value)}
                                    inputLabel="Assessment Name"
                                />
                            </div>

                            {/* Assessment Base Select */}
                            <div className="md:w-1/4 lg:w-1/5 w-full">
                                <CustomSelect
                                    value={AssessmentSearchQueryWithBase}
                                    onChange={(e) => setAssessmentSearchQueryWithBase(e.target.value)}
                                    label="Assessment Basis"
                                    options={assessmentBasisData}
                                    dataField="value"
                                    dataText="label"
                                />
                            </div>
                        </div>

                        {/* Add Button */}
                        {hasPermission(permissionsNameForThisPage.Add) && <div className='-mb-3'>
                            <AddIconButton action={() => navigate('/createAsssessment')} />
                        </div>}
                    </div>

                    <TableComp
                        data={filteredData}
                        columns={columns}
                        actions={AllowedActions}
                    />
                </>

            )}
        </div>
    );
}

export default ManageAssessments;
