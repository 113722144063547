import React, { useState, useEffect } from 'react';
import './App.css';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import { Button, IconButton, TextField } from '@mui/material';
import { CloseIconButton, EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';

function EditModal({ pageHeading, data, setStatus, setRecentformData, EditParam, tableName }) {
    const [message, setMessage] = useState('');
    const [Data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const api_url = process.env.REACT_APP_API_URL;
    useEffect(() => {
        setData(data);
    }, [data]);


    const formik = useFormik({
        initialValues: data,
        onSubmit: async (values) => {
            console.log(values);
            const payload = {
                tableName,
                data: values
            }
            sendDataToServer(payload);
        }
    })



    const sendDataToServer = async (formData) => {
        const res = await fetch(`${api_url}/manage/Edit`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
        });

        const result = await res.json();
        setMessage(result.message);
        if (res.ok) {
            setStatus(true);
            setRecentformData(formData);
        } else {
            console.error("Error while updating");
        }
    };
    return (
        <div>
            {!open && (

                <EditIconButton action={handleOpen} id={Object.values(data)[0]} />
            )}
            {open && (
                <div className='modal-overlay flex justify-center items-center min-h-screen bg-gray-100'>
                    <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md  '>
                        <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                            <div className='invisible'>content</div>
                            <h2 className='text-xl text-center'>Edit {pageHeading}</h2>
                            <div className='self-center'>
                                <CloseIconButton action={handleClose} />
                            </div>
                        </div>
                        <form className='flex flex-col   px-5 mb-5 gap-10' onSubmit={formik.handleSubmit}>
                            {EditParam.map((key, index) => (
                                <div key={index} className='flex flex-col'>


                                    <InputBox
                                        inputLabel={key}
                                        required='true'
                                        inputId={`edit-${key}`}
                                        type={'text'}
                                        inputName={key}
                                        onChange={formik.handleChange}
                                        inputValue={formik.values[key] || ''}

                                    />
                                </div>
                            ))}



                            {message && <div className='text-yellow-300'>{message}</div>}
                            <Button sx={{ textTransform: 'none' }} variant='contained' id='Update' type='submit'>Update </Button>
                        </form>
                    </div>

                </div>

            )}
        </div>
    );
}

export default EditModal;
