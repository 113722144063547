import React, { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, TextField } from '@mui/material';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
function EditSkillModal({ Data, handleClose, setStatus, setRecenteditFormData }) {
    const [message, setMessage] = useState('');
    const api_url = process.env.REACT_APP_API_URL
    const [formData, setFormData] = useState({
        JobroleId: '',
        jobroleName: '',
        jobroleDescription: '',

    });

    useEffect(() => {
        if (Data) {
            setFormData({
                jobroleId: Data.jobroleId,
                jobroleName: Data.jobroleName,
                jobroleDescription: Data.jobroleDescription,

            });
        }
    }, [Data]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        sendDataToServer(formData);
    };

    const sendDataToServer = async (formData) => {
        const res = await fetch(`${api_url}/jobroles/update`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
        });

        const result = await res.json();
        console.log(result);
        setMessage(result.message);
        if (res.ok) {
            console.log("data updated");
            setStatus(true);
            setRecenteditFormData(formData);
            handleClose();
        } else {
            console.log("error while updating");
        }
    };

    console.log(message);

    return (
        <div className='modal-overlay'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit Job Role"} />

                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>
                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={handleSubmit}>
                    <div className='flex flex-col'>
                        <InputBox
                            inputLabel='Jobrole'
                            required='true'
                            inputId='jobrole'
                            type='text'
                            inputName='jobroleName'
                            onChange={handleChange}
                            inputValue={formData.jobroleName}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <InputBox
                            inputLabel='Desc'
                            type='text'
                            inputId='jobroledesc'
                            inputName='jobroleDescription'
                            onChange={handleChange}
                            inputValue={formData.jobroleDescription}
                        />
                    </div>

                    {message && <div className='text-yellow-300'>{message}</div>}
                    <Button sx={{ textTransform: 'none' }} variant='contained' type='submit'>Update jobrole</Button>
                </form>
            </div>
        </div>
    );
}

export default EditSkillModal;
