import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Divider, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Heading from '../reusableComp/Heading';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';

function Login() {
    const [showPassword, setShowPassword] = useState(false);

    const [message, setMessage] = useState({
        status: '',
        message: '',
    });

    const navigate = useNavigate();

    // Validation schema using Yup
    const validationSchema = Yup.object({
        Email: Yup.string()
            .email('Invalid email address') // Email must be a valid email format
            .required('Email is required*'), // Email is required
        Password: Yup.string()
            .required('Password is required*'), // Password is required
    });

    const formik = useFormik({
        initialValues: {
            Email: '',
            Password: '',
        },
        validationSchema, // Attach Yup validation schema
        onSubmit: values => {
            console.log(values);
            LoginAction(values);
        },
    });

    const LoginAction = async (values) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        });

        const result = await response.json();
        console.log(result);

        if (response.ok) {
            console.log('logged in');
            localStorage.setItem('token', result.token);
            setMessage({
                status: 'green',
                message: result.message,
            });
            navigate('/');
        } else {
            setMessage({
                status: 'red',
                message: result.message,
            });
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">

            <div className='flex flex-col '>
                <div className="w-full bg-sky-600 text-gray-50 h-1/6 rounded-t-md">
                    <h1 className="text-2xl text-center p-2">Wise Assessment</h1>
                    <Divider orientation="horizontal" />
                </div>
                <form
                    className="flex flex-col gap-6 border border-gray-300 rounded-b-md p-8 bg-white shadow-lg w-full max-w-md"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                >

                    <Heading heading={"Login"} />
                    <InputBox

                        autoComplete="off"
                        inputId="Email"
                        inputLabel="Email"

                        inputName="Email"
                        inputValue={formik.values.Email}
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} // Tracks field focus for validation
                        className='w-full'
                        error={formik.touched.Email && Boolean(formik.errors.Email)} // Shows error only if the field has been touched
                        helperText={formik.touched.Email && formik.errors.Email} // Displays error message
                    />

                    <InputBox

                        inputId="Password"
                        inputLabel="Password"
                        inputName="Password"
                        inputValue={formik.values.Password}
                        type={showPassword ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='w-full'
                        error={formik.touched.Password && Boolean(formik.errors.Password)}
                        helperText={formik.touched.Password && formik.errors.Password}
                    />

                    <p className={`${message.status === 'red' ? 'text-red-600' : 'text-green-600'} text-center`}>
                        {message.message}
                    </p>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                    >
                        Login
                    </Button>

                    <p className="text-center text-gray-700">
                        Don't have an account? <Link to="/signUp" className="text-blue-900">Create</Link> a new one
                    </p>
                </form>
            </div>
        </div>
    );
}

export default Login;
