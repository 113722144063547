import React from 'react'

function Home() {
    return (
        <div className='flex justify-center items-center h-screen'>
            home page
        </div>
    )
}

export default Home
