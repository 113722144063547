import { Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import sampleData from './sampleData'; // Ensure this contains relevant data
import { AddCircleOutlined, AddOutlined, RemoveCircle } from '@mui/icons-material';
import Profile from './Profile';
import Heading from '../../reusableComp/Heading';

const ProfileFilter = () => {
    const [filters, setFilters] = useState([
        { id: 1, competency: '', scoreCondition: '', score: '' }
    ]);
    const [rankFilter, setRankFilter] = useState('');
    const [labFilter, setLabFilter] = useState('');
    const [areaOfInterestFilter, setAreaOfInterestFilter] = useState('');
    const [filteredData, setFilteredData] = useState(sampleData);

    useEffect(() => {
        // Initialize filteredData with sampleData on component mount
        setFilteredData(sampleData);
    }, []);

    const handleFilterChange = (index, field, value) => {
        const newFilters = [...filters];
        newFilters[index][field] = value;
        setFilters(newFilters);
    };

    const addFilterRow = () => {
        setFilters(prevFilters => [
            ...prevFilters,
            { id: prevFilters.length + 1, competency: '', scoreCondition: '', score: '' }
        ]);
    };

    const removeFilterRow = (index) => {
        if (filters.length > 1) {
            setFilters(filters.filter((_, i) => i !== index));
        }
    };

    const applyFilters = () => {
        let result = [...sampleData]; // Start with a fresh copy of the original data

        // Apply Lab filter first
        if (labFilter) {
            result = result.filter(profile => profile.lab === labFilter);
        }

        // Apply Rank filter next
        if (rankFilter) {
            result = result.filter(profile => profile.rank === rankFilter);
        }

        // Apply Area of Interest filter next
        if (areaOfInterestFilter) {
            result = result.filter(profile => profile.areaOfInterest === areaOfInterestFilter);
        }

        // Apply Competency, Score Condition, and Score filters in sequence
        filters.forEach(filter => {
            if (filter.competency) {
                const competency = filter.competency.toLowerCase();
                const scoreCondition = filter.scoreCondition;
                const score = parseInt(filter.score, 10);

                result = result.filter(profile => {
                    const competencyMatch = profile.competencies.find(c =>
                        c.competency.toLowerCase().includes(competency)
                    );

                    if (!competencyMatch) {
                        return false;
                    }

                    const competencyScore = competencyMatch.score;

                    if (!isNaN(score)) {
                        if (scoreCondition === 'equal' && competencyScore !== score) {
                            return false;
                        } else if (scoreCondition === 'greater' && competencyScore <= score) {
                            return false;
                        } else if (scoreCondition === 'less' && competencyScore >= score) {
                            return false;
                        }
                    }

                    return true;
                });
            }
        });

        setFilteredData(result);
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <Heading heading={"Profile Filter"} />
            <form className="mb-6 p-4 border rounded-md">
                <div className="mb-4">
                    <div className="flex flex-col space-y-4">
                        <div className='flex justify-between gap-2'>
                            <div className='w-full'>
                                <label className="block text-sm font-medium">Lab</label>
                                <select
                                    value={labFilter}
                                    onChange={(e) => setLabFilter(e.target.value)}
                                    className="mt-1 p-2 border rounded w-full"
                                >
                                    <option value="">Select</option>
                                    <option value="DIPR">DIPR</option>
                                    <option value="DRL">DRL</option>
                                    <option value="SSPL">SSPL</option>
                                    <option value="INMAS">INMAS</option>
                                    <option value="DIPAS">DIPAS</option>
                                </select>
                            </div>
                            <div className='w-full'>
                                <label className="block text-sm font-medium">Rank</label>
                                <select
                                    value={rankFilter}
                                    onChange={(e) => setRankFilter(e.target.value)}
                                    className="mt-1 p-2 border rounded w-full"
                                >
                                    <option value="">Select</option>
                                    <option value="Sc. F">Sc. F</option>
                                    <option value="Sc. E">Sc. E</option>
                                    <option value="Sc. D">Sc. D</option>
                                    <option value="Sc. D">Sc. C</option>
                                </select>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium">Area of Interest / Preferred Role</label>
                            <select
                                value={areaOfInterestFilter}
                                onChange={(e) => setAreaOfInterestFilter(e.target.value)}
                                className="mt-1 p-2 border rounded w-full"
                            >
                                <option value="">Select</option>
                                <option value="MMG">MMG</option>
                                <option value="Training">Training</option>
                                <option value="Technical Coordination">Technical Coordination</option>
                                <option value="Publication/Research">Publication/Research</option>
                                <option value="Rajbhasha">Rajbhasha</option>

                            </select>
                        </div>
                    </div>
                </div>

                {filters.map((filter, index) => (
                    <div key={filter.id} className=" mb-4">
                        <div className="flex flex-col space-y-4">
                            <div className='flex justify-between  gap-2 align-middle'>
                                <div className='w-full'>
                                    <label className="block text-sm font-medium">Competency</label>
                                    <select
                                        value={filter.competency}
                                        onChange={(e) => handleFilterChange(index, 'competency', e.target.value)}
                                        className="mt-1 p-2 border rounded w-full"
                                    >
                                        <option value="">Select</option>
                                        <option value="Leadership">Leadership</option>
                                        <option value="Team Collaboration">Team Collaboration</option>
                                        <option value="Innovation">Innovation</option>
                                        <option value="Project Management">Project Management</option>
                                        <option value="Communication">Communication</option>
                                    </select>
                                </div>
                                <div className='w-full'>
                                    <label className="block text-sm font-medium invisible">Score Condition</label>
                                    <select
                                        value={filter.scoreCondition}
                                        onChange={(e) => handleFilterChange(index, 'scoreCondition', e.target.value)}
                                        className="mt-1 p-2 border rounded w-full"
                                    >
                                        <option value=''>Select</option>
                                        <option value="equal">Equal to =</option>
                                        <option value="greater">Greater than &gt;</option>
                                        <option value="less">Less than &lt;</option>
                                    </select>
                                </div>
                                <div className='w-full'>
                                    <label className="block text-sm font-medium">Score</label>
                                    <select
                                        value={filter.score}
                                        onChange={(e) => handleFilterChange(index, 'score', e.target.value)}
                                        className="mt-1 p-2 border rounded w-full"
                                    >
                                        {[...Array(10).keys()].map(i => (
                                            <option key={i} value={(i + 1)}>{(i + 1)}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center justify-center '>
                                    <IconButton
                                        variant='outlined'
                                        type="button"
                                        onClick={() => removeFilterRow(index)}
                                        className=" text-white p-2  rounded "
                                        title='remove filter'
                                    >
                                        <RemoveCircle className='my-5 text-red-900' />
                                    </IconButton>
                                </div>

                            </div>


                        </div>
                    </div>
                ))}
                <div className='flex justify-end gap-2'>
                    <Button
                        variant='outlined'
                        type="button"
                        onClick={applyFilters}
                        className="bg-green-500 text-white p-2 rounded"
                    >
                        Apply Filters
                    </Button>
                    <IconButton
                        variant='outlined'
                        type="button"
                        onClick={addFilterRow}
                        className="mb-4 bg-blue-500 text-white p-2 rounded"
                        title='add filter'
                    >
                        <AddCircleOutlined className='text-blue-800' />
                    </IconButton>

                </div>
            </form>
            <div className='my-5 text-red-500 font-extralight'>
                {filteredData && filteredData.length} items found!
            </div>



            <table className="min-w-full bg-white  ">
                {filteredData.length > 0 ? (
                    filteredData.map(profile => (
                        <div className='my-3'>
                            <Profile data={profile} />
                        </div>
                    ))
                ) : (
                    <tr>
                        <td colSpan="6" className="p-3 text-center">No data found</td>
                    </tr>
                )}
            </table>
        </div>
    );
};

export default ProfileFilter;
