import React, { useEffect, useState } from 'react';
import Heading from '../../reusableComp/Heading';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { CustomSelect, CustomSelectv2, InputBox, InputWithDropDown } from '../../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function EditUserRoleModal({ Data, handleClose, setStatus, setRecenteditFormData }) {
    const [message, setMessage] = useState('');
    const [UserData, setUserData] = useState([]);
    const [RoleData, setRoleData] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;

    const validationSchema = Yup.object({
        UserId: Yup.string().required('User is required'),
        RoleIds: Yup.array().min(1, 'At least one menu must be selected').required('Roles are required')
    });

    console.log(Data)
    const formik = useFormik({
        initialValues: {
            UserId: '',
            RoleIds: ''
        },
        validationSchema,
        onSubmit: values => {
            console.log(values);
            sendDataToServer(values);
        }
    });

    const sendDataToServer = async (values) => {
        const res = await fetch(`${api_url}/UserRoles/update`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values)
        });

        const result = await res.json();
        console.log(result);
        setMessage(result.message);
        if (res.ok) {
            console.log("Data updated");
            setStatus(true);
            setRecenteditFormData(values);
            handleClose();
        } else {
            console.log("Error while updating");
        }
    };

    const getUserData = async () => {
        const response = await fetch(`${api_url}/manage/getData`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: 'users' })
        });
        const result = await response.json();

        if (response.ok) {
            setUserData(result.data);
        }
    };

    const getRoleData = async () => {
        const response = await fetch(`${api_url}/manage/getData`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: 'staticroles' })
        });
        const result = await response.json();

        if (response.ok) {
            setRoleData(result.data);
        }
    };

    useEffect(() => {
        getUserData();
        getRoleData();
    }, []);

    useEffect(() => {
        if (Data) {
            const IdarrayOfroles = Data.Roles.map(item => item.RolesId)
            console.log(IdarrayOfroles)
            formik.setValues({
                UserId: Data.UserId,
                RoleIds: IdarrayOfroles

            });
        }
    }, [Data]);

    return (
        <div className='modal-overlay'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit User Role"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>
                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    <div className='flex flex-col'>
                        {UserData && (
                            <CustomSelect
                                options={UserData}
                                dataField='Id'
                                dataText='Name'
                                inputId='User'
                                label='Select User'
                                name='UserId'
                                value={formik.values.UserId}
                                onChange={formik.handleChange}
                                error={formik.touched.UserId && Boolean(formik.errors.Id)}
                                helperText={formik.touched.UserId && formik.errors.Id}
                            />
                        )}
                    </div>
                    <div className='flex flex-col'>
                        {RoleData && (
                            <CustomSelectv2
                                options={RoleData}
                                multiple
                                dataField='RolesId'
                                dataText='Role'
                                inputId='Role'
                                label='Select Role'
                                name='RoleIds'
                                value={formik.values.RoleIds}
                                onChange={(value) => formik.setFieldValue("RoleIds", value)}
                                error={formik.touched.RoleIds && Boolean(formik.errors.RoleIds)}
                                helperText={formik.touched.RoleIds && formik.errors.RoleIds}
                            />
                        )}
                    </div>
                    {message && <div className='text-yellow-300'>{message}</div>}
                    <Button sx={{ textTransform: 'none' }} variant='contained' type='submit'>
                        Update Job User
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default EditUserRoleModal;
