import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../navigation/Header';
import { CircularProgress } from '@mui/material';
import NavigatorDrawer from '../navigation/NavigatorDrawer';

import { jwtDecode } from 'jwt-decode';
import Unauthorised from './Unauthorised';

function ProtectedRoute({ element: Element }) {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const token = localStorage.getItem('token')
    const tokenData = jwtDecode(token)
    console.log(tokenData.userId)
    localStorage.setItem('userId', tokenData.userId)
    // const data = jwtDecode(token || '');




    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            const tokenAuthentication = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/login/protected`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                    }
                } catch (error) {
                    console.error('Error verifying token:', error);
                    setIsAuthenticated(false);
                }
            };

            tokenAuthentication();
        } else {
            setIsAuthenticated(false);
        }
    }, [navigate]);

    if (isAuthenticated === null) {
        return <div className='flex justify-center items-center h-screen'><CircularProgress /></div>;
    }
    if (isAuthenticated) {

        return <><Header /> <NavigatorDrawer element={Element} /></>



    }
    else {
        return navigate('/login');
    }
}

export default ProtectedRoute;
