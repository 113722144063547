import React, { useEffect, useState } from 'react';
import AddModal from './AddModal';
import EditModal from './EditModal';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import './App.css'
import { RemoveRedEyeOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SearchIconButton, ViewIconButton } from '../../../../ButtonsIconsInputs/IconButtons';
import DeleteConfirmation from '../../../reusableComp/Deletecomfirmation';
import TableComp from '../../../reusableComp/TableComp';
import Heading from '../../../reusableComp/Heading';
import { InputBox } from '../../../../ButtonsIconsInputs/CustomInputs';

function ManageModule({ pageHeading, data, tableHeadings, formfieldNames, setRecentformData, setInsertionStatus, tableName, columns }) {
    const permissionsNameForThisPage = {
        Delete: 'AssessmentResult-Delete',

        View: 'AssessmentResult-View',
    }
    const [Data, setData] = useState();
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [editStatus, setEditStatus] = useState(false);
    const [filteredData, setfilteredData] = useState(data);




    const [datakeys, setdatakeys] = useState(tableHeadings)

    const navigate = useNavigate()

    console.log(datakeys)
    console.log(Data)
    const api_url = process.env.REACT_APP_API_URL

    useEffect(() => {
        setData(data)
        setfilteredData(data)
    }, [data])


    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }


    const handleDelete = async (id, idname) => {
        const payload = {
            tableName,
            data: { [idname]: id }
        };
        const res = await fetch(`${api_url}/manage/delete/`, {
            method: 'delete',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify(payload)
        });
        if (res.ok) {
            console.log("deleted successfully");
            console.log(datakeys)
            const updateData = Data.filter(item => item[Object.keys(item)[0]] !== id);
            setData(updateData);
            setfilteredData(updateData);
        } else {
            console.log("error occurred");
        }
    }

    const handleSearch = (e) => {
        console.log(query);
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (datakeys) {
            if (!searchQuery) {
                setfilteredData(Data);
            } else {
                const result = Data.filter(item =>
                    Object.values(item).some(value =>
                        value?.toString().toLowerCase().includes(searchQuery)
                    )
                );
                setfilteredData(result);

            }
        } else {
            setfilteredData(data);
        }
    }

    useEffect(() => {
        if (editStatus) {
            // getCandidatesDetails() need to get letest data on edit
        }
    }, [editStatus]);


    const actions = [
        {
            label: 'Delete',
            handler: handleDelete,
            tooltip: 'Edit item',
            ButtonComponent: DeleteConfirmation, // Use EditIconButton for edit action
            requiredPermission: permissionsNameForThisPage.Delete,

        },
        {
            label: 'View report',
            handler: (item) => navigate('/AssessmentReport', { state: { data: item } }),
            tooltip: 'View',
            ButtonComponent: ViewIconButton, // Use DeleteIconButton for delete action
            requiredPermission: permissionsNameForThisPage.View,

        },
    ]


    return (
        <div className="container mx-auto py-8">
            <Heading heading='Assessment Results' />
            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputIdd='search'
                        inputLabel="search"
                        inputValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>

            </div>
            <div className="overflow-x-auto">
                {/* {filteredData.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-200">
                        <thead>
                            <tr className="" style={{ backgroundColor: '#6495ED' }}>


                                <th className="border p-2 text-gray-50">Name</th>
                                <th className="border p-2  text-gray-50"> Assessment Name</th>
                                <th className="border p-2  text-gray-50"> Assessment Date</th>
                                <th className="border p-2  text-gray-50">Final Score</th>


                                <th className="border p-2  text-gray-50">action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {filteredData?.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-50">


                                    <td className="border p-2">{item.Name}</td>
                                    <td className="border p-2">{item.NameOfAssessment}</td>
                                    <td className="border p-2">{item.AssessmentDateTime}</td>
                                    <td className="border p-2">{item.finalScore}</td>



                                    <td className="border p-2">
                                        <div className='flex items-center gap-2'>

                                            <DeleteConfirmation className='text-red-800' index={index} handleDelete={() => handleDelete(Object.values(item)[0], Object.keys(item)[0])} />

                                            <ViewIconButton action={() => navigate('/AssessmentReport')} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                ) : (
                    <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
                        <p className="text-lg font-semibold">No Data Found</p>
                    </div>
                )} */}

                <TableComp
                    actions={actions}
                    columns={columns}
                    data={filteredData}
                />

            </div>

        </div>
    );
}

export default ManageModule;




