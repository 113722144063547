import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { Accordion, AccordionDetails, AccordionSummary, Collapse, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material';
import { Expand, ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import { CustomSelectv2 } from '../../../ButtonsIconsInputs/CustomInputs';
import DeleteConfirmation from './Deletecomfirmation';
import { hasPermission } from '../../reusableComp/PermissionCheck';

const DataTable = ({ UserData = [], handleDelete, handleEditOpen }) => {
    let rowsPerPage = 10
    const [page, setPage] = useState(1);




    const handleChange = (event, value) => {
        setPage(value);
    };
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = UserData?.slice(startIndex, endIndex);

    useEffect(() => {
        const totalPages = Math.ceil(UserData.length / rowsPerPage);
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages);
        } else if (UserData.length === 0) {
            setPage(1);
        }
    }, [UserData, page, rowsPerPage]);
    return (
        <div className="overflow-x-auto">
            {paginatedData?.length > 0 ? (
                <>
                    <table className="w-full border-collapse border border-gray-200">
                        <thead>
                            <tr className="text-gray-50 bg-sky-600">

                                <th className="border p-2 text-start">
                                    Role
                                </th>

                                <th className="border p-2 text-start">
                                    Permission(s)
                                </th>

                                {(hasPermission('RolePermission-Edit') || hasPermission('RolePermission-Delete')) && <th className="border p-2 w-fit">Action</th>
                                }                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData?.map((Role, index) => (
                                <tr key={index} className="hover:bg-gray-50">

                                    <td className="border p-2 w-2/5">
                                        {Role.roleName}
                                    </td>

                                    <td className="border w-2/5 p-2 ">
                                        <span>
                                            {Role.permissions.map((item) => item.permissionName).join(', ')}
                                        </span>
                                    </td>
                                    {(hasPermission('RolePermission-Edit') || hasPermission('RolePermission-Delete')) && <td className="border p-2 w-1/5">
                                        <div className="flex justify-around">

                                            {hasPermission('RolePermission-Delete') && <DeleteConfirmation action={() => handleDelete(Role.roleId)} itemData={Role.roleName} />
                                            }
                                            {hasPermission('RolePermission-Edit') && <EditIconButton action={() => handleEditOpen(Role)} />
                                            }
                                        </div>
                                    </td>

                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </>
            ) : (
                <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
                    <p className="text-lg font-semibold">No Data Found</p>
                </div>
            )}
            <div className="flex justify-center mt-4">
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(UserData.length / rowsPerPage)}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                    />
                </Stack>
            </div>
        </div>
    );
};

DataTable.propTypes = {
    UserData: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
    })).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        handler: PropTypes.func.isRequired,
        tooltip: PropTypes.string,
        className: PropTypes.string,
        ButtonComponent: PropTypes.elementType.isRequired, // Custom button component
    })).isRequired,
};



export default DataTable;
