import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ShowJobRole({ onJobRolesChange, existingData = [] }) {
    const [jobRoles, setJobRoles] = useState([]);
    const [selectedJobRoleIds, setSelectedJobRoleIds] = useState(existingData);
    console.log(existingData)
    console.log(selectedJobRoleIds)
    useEffect(() => {
        const fetchJobRoles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/jobroles`);
                const data = await response.json();
                setJobRoles(data.data);
            } catch (error) {
                console.error('Failed to fetch job roles:', error);
            }
        };

        fetchJobRoles();
    }, []);

    const handleChange = (event) => {
        const selectedNames = event.target.value;
        const selectedIds = jobRoles
            .filter(jobRole => selectedNames.includes(jobRole.jobroleName))
            .map(jobRole => ({ id: jobRole.jobroleId }));

        setSelectedJobRoleIds(selectedIds);
        onJobRolesChange(selectedIds);
        console.log(selectedIds);
    };

    return (
        <div className="mb-4 flex justify-between  rounded-lg px-4 pt-2 gap-1">
            <FormControl variant='standard' className='w-full '>
                <TextField
                    select
                    variant='standard'
                    label='Select Job Roles'
                    labelId="job-role-checkbox-label"
                    id="job-role-checkbox"
                    value={jobRoles.filter(jobRole => selectedJobRoleIds.some(role => role.id === jobRole.jobroleId)).map(jobRole => jobRole.jobroleName)}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select Job Roles" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {jobRoles.map((jobRole) => (
                        <MenuItem key={jobRole.jobroleId} value={jobRole.jobroleName}>
                            <ListItemText primary={jobRole.jobroleName} />
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
        </div>
    );
}
