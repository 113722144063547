import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddCircleOutline, AttachFile, Close, Remove, RemoveCircleOutline } from '@mui/icons-material';
import ImageUpload from '../reusableComp/ImageUpload'; // Import the ImageUpload component
import SkillsDropdown from './SkillsDropdown';
import Heading from '../reusableComp/Heading';
import { useLocation } from 'react-router-dom';
import { CustomSelect, InputBox } from '../../ButtonsIconsInputs/CustomInputs';

function EditQuestion() {
    const api_url = process.env.REACT_APP_API_URL;
    const [skill, setSkill] = useState('');
    const [questionType, setQuestionType] = useState('');
    const [question, setQuestion] = useState('');
    const [questionImages, setQuestionImages] = useState([]);
    const [difficulty, setDifficulty] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([{ option: '', image: null, isCorrect: false }]);
    const [freeTypeAnswer, setFreeTypeAnswer] = useState('');
    const [freeTypeImage, setFreeTypeImage] = useState(null);
    const [api_res_status, setApi_res_status] = useState(null);
    const [questionId, setQuestionId] = useState()

    const location = useLocation()
    const data = location.state.data;

    useEffect(() => {
        getAnswer(data.questionId, data.questionType)
        getQuestionImages(data.questionId)
        setQuestionId(data.questionId)
        setQuestion(data.question)
        setDifficulty(data.difficulty)
        setSkill(data.skillId)
        setQuestionType(data.questionType)
        console.log(data)


    }, [data])


    const getAnswer = async (QuestionId, QuestionType) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/questions_answers/getAnswers`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ questionId: QuestionId, questionType: QuestionType })
            });
            const response = await result.json()
            if (result.ok) {
                if (QuestionType.includes('mcq')) {
                    const updatedOptions = await Promise.all(response.data.map(async (option) => {
                        const imageResponse = await fetch(option.optionImage);

                        if (!imageResponse.ok) {
                            throw new Error('Failed to fetch image: ' + option.optionImage);
                        }

                        const blob = await imageResponse.blob();

                        const file = new File([blob], 'image-' + option.optionText.replace(/\s+/g, '-') + '.jpg', { type: blob.type });

                        return {
                            option: option.optionText,
                            image: file,
                            isCorrect: option.isCorrect
                        };
                    }));

                    setOptions(updatedOptions)

                }

                else {
                    try {
                        const Answer = response.data;
                        const imageResponse = await fetch(Answer.answerImage);

                        // Check if the fetch was successful
                        if (!imageResponse.ok) {
                            throw new Error('Failed to fetch image: ' + Answer.answerImage);
                        }

                        // Convert the response to a Blob
                        const blob = await imageResponse.blob();

                        // Create a File object from the Blob
                        const file = new File([blob], 'image-' + Answer.answerText.replace(/\s+/g, '-') + '.jpg', { type: blob.type });

                        // Return the File object
                        setFreeTypeAnswer(Answer.answerText)
                        setFreeTypeImage(file)
                    } catch (error) {
                        console.error('Error fetching image:', error);
                        return null; // Return null if an error occurs
                    }


                }
            }
        } catch (error) {
            console.log(error.message);
        }
    };


    const getQuestionImages = async (questionId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/questions_answers/questionImages/?questionId=${questionId}`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
            });
            const imageUrls = await result.json();
            if (result.ok) {
                const imageFiles = await Promise.all(
                    imageUrls.map(async (url) => {
                        const response = await fetch(url);
                        const blob = await response.blob();
                        return new File([blob], url.split('/').pop(), { type: blob.type });
                    })
                );
                setQuestionImages(imageFiles); // Store files in the state
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + questionImages.length > 10) {
            alert('You can only upload a maximum of 10 images.');
            return;
        }
        setQuestionImages([...questionImages, ...files]);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = questionImages.filter((_, i) => i !== index);
        setQuestionImages(updatedImages);
    };

    const handleOptionImageChange = (index, image) => {
        const updatedOptions = [...options];
        updatedOptions[index].image = image;
        setOptions(updatedOptions);
    };

    const handleCorrectOptionChange = (index) => {
        const updatedOptions = [...options];
        if (questionType == 'mcq-single') {
            updatedOptions.forEach((option, i) => {
                option.isCorrect = i === index;
            });
        } else {
            updatedOptions[index].isCorrect = !updatedOptions[index].isCorrect;
        }
        setOptions(updatedOptions);
    };

    const handleSubmit = async () => {
        console.log("clicked")
        if (!skill || !questionType || !question || !difficulty) {
            setApi_res_status('Please fill in all required fields.');
            return;
        }

        if (questionType.includes('mcq')) {
            if (options.some((option) => !option.option)) {
                setApi_res_status('Please fill in all option fields.');
                return;
            }
            if (!options.some((option) => option.isCorrect)) {
                setApi_res_status('Please mark at least one option as correct.');
                return;
            }
        } else {
            if (!freeTypeAnswer) {
                setApi_res_status('Please fill in the free text answer.');
                return;
            }


        }

        setLoading(true);
        const formData = new FormData();
        formData.append('skill', skill);
        formData.append('questionType', questionType);
        formData.append('question', question);
        formData.append('difficulty', difficulty);
        questionImages.forEach((image) => {
            formData.append('questionImages', image);
        });

        if (questionType.includes('mcq')) {
            options.forEach((option, index) => {
                formData.append(`options[${index}][option]`, option.option);
                if (option.image) {
                    formData.append(`optionsimage`, option.image);
                }
                formData.append(`options[${index}][isCorrect]`, option.isCorrect);
            });
        } else {
            formData.append('freeTypeAnswer', freeTypeAnswer);
            if (freeTypeImage) {
                formData.append('freeTypeImage', freeTypeImage);
            }
        }

        try {
            console.log(formData)
            const response = await fetch(`${api_url}/questions_answers/update/?id=${questionId}`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (response.ok) {
                setApi_res_status('Question and Answer submitted successfully!');
            } else {
                setApi_res_status('Failed to save question and answer.');
            }
        } catch (error) {
            setApi_res_status('An error occurred while saving.');
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setSkill('');
        setQuestionType('');
        setQuestion('');
        setQuestionImages([]);
        setOptions([{ option: '', image: null, isCorrect: false }]);
        setFreeTypeAnswer('');
        setFreeTypeImage(null);
        setApi_res_status(null);
        setDifficulty('');
    };

    const TypeOfQuestion = [
        { label: 'Multiple Choice - Single Correct', value: 'mcq-single' },
        { label: 'Multiple Choice - Multiple Correct', value: 'mcq-multiple' },
        { label: 'Free Text', value: 'free' },


    ]

    const difficultyLevelData = [
        { label: 'Easy', value: 'easy' },
        { label: 'Normal', value: 'normal' },
        { label: 'Hard', value: 'hard' }
    ]
    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <div className="lg:w-3/4 mx-auto bg-white  rounded-lg shadow-md">
                <div className='w-full bg-sky-600 rounded-t-md text-gray-50 py-2'>
                    <Heading heading={"Edit Question"} />
                </div>
                <div className='p-6'>
                    <div className="mb-4">
                        <SkillsDropdown skill={skill} setSkill={setSkill} />
                    </div>
                    <div className="flex justify-between gap-3 w-full mb-4">

                        {/* <label className="block text-sm font-medium text-gray-700 mb-2">Type of Question</label>
                            <select
                                value={questionType}
                                onChange={(e) => setQuestionType(e.target.value)}
                                className="block w-full p-2 border border-gray-300 rounded-md"
                            >
                                <option value="">Select type of question</option>
                                <option value="mcq-single">Multiple Choice - Single Correct</option>
                                <option value="mcq-multiple">Multiple Choice - Multiple Correct</option>
                                <option value="free">Free Text</option>
                            </select> */}
                        <div className='w-1/2'>
                            <CustomSelect
                                className=' w-full'
                                options={TypeOfQuestion}
                                value={questionType}
                                onChange={(e) => setQuestionType(e.target.value)}
                                label='Type Of Question'
                                dataField='value'
                                dataText='label'


                            />
                        </div>

                        <div className='w-1/2'>
                            {/* <label className="block text-sm font-medium text-gray-700 mb-2">Difficulty Level</label>
                        <select
                            value={difficulty}
                            onChange={(e) => setDifficulty(e.target.value)}
                            className="block w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Select difficulty level</option>
                            <option value="easy">Easy</option>
                            <option value="normal">Normal</option>
                            <option value="difficult">Difficult</option>
                        </select> */}
                            <CustomSelect
                                className='w-1/2'
                                options={difficultyLevelData}
                                value={difficulty}
                                onChange={(e) => setDifficulty(e.target.value)}
                                label='Difficulty Level'
                                dataField='value'
                                dataText='label'

                            />
                        </div>
                    </div>
                    <div className="relative mb-4">
                        <div className="flex items-center relative mb-2">
                            <InputBox

                                inputLabel="Type question"
                                inputValue={question}
                                multiline='true'
                                type='text'
                                onChange={(e) => setQuestion(e.target.value)}
                                className="w-max   border  "
                            />

                            <label className=" cursor-pointer">
                                <AttachFile />
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageChange}
                                    className="hidden"
                                />
                            </label>
                        </div>
                        <ImageUpload images={questionImages} onRemove={handleRemoveImage} />
                    </div>

                    {questionType.includes('mcq') && (
                        <div className="  space-y-6 mb-4">
                            {options?.map((option, index) => (
                                <div key={index} className="relative p-4 border border-gray-300 rounded-lg shadow-sm">
                                    <div className="absolute top-2 right-2">
                                        <input
                                            type={questionType === 'mcq-single' ? 'radio' : 'checkbox'}
                                            name="correctOption"

                                            checked={option.isCorrect}
                                            onChange={() => handleCorrectOptionChange(index)}
                                            className="mr-2"
                                        />
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <span className="font-semibold text-gray-700 invisible">Option {index + 1}</span>
                                    </div>
                                    <div className="flex items-center relative mb-4">
                                        <InputBox
                                            inputLabel={`Option ${index + 1}`}
                                            inputValue={option.option}
                                            multiline='true'
                                            onChange={(e) => {
                                                const updatedOptions = [...options];
                                                updatedOptions[index].option = e.target.value;
                                                setOptions(updatedOptions);
                                            }}
                                            className="block w-max p-2 pr-14 border border-gray-300 rounded-md"
                                        />
                                        <label className=" cursor-pointer">
                                            <AttachFile />
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleOptionImageChange(index, e.target.files[0])}
                                                className="hidden"
                                            />
                                        </label>
                                    </div>
                                    {option.image && (
                                        <div className="flex items-center mb-4">
                                            <img
                                                src={URL.createObjectURL(option.image)}
                                                alt={`option ${index}`}
                                                className="w-32 h-32 object-cover rounded-md"
                                            />
                                            <button
                                                onClick={() => handleOptionImageChange(index, null)}
                                                className="ml-2 text-red-700 border-none bg-white hover:text-gray-600"
                                                title="Remove image"
                                            >
                                                <RemoveCircleOutline />
                                            </button>
                                        </div>
                                    )}
                                    <button
                                        onClick={() => setOptions(options.filter((_, i) => i !== index))}
                                        className="absolute -bottom-4 right-2 text-red-700 border-none bg-white hover:text-gray-600"
                                        title="Remove Option"
                                    >
                                        <RemoveCircleOutline />
                                    </button>
                                </div>
                            ))}
                            <div className='flex justify-end'>
                                <button
                                    onClick={() => setOptions([...options, { option: '', image: null, isCorrect: false }])}
                                    className='border-none -mt-12 mr-12 z-10 text-blue-600 bg-white hover:text-blue-900'
                                    title='Add option'
                                >
                                    <AddCircleOutline />
                                </button>
                            </div>

                        </div>
                    )}

                    {questionType === 'free' && (
                        <div>
                            <div className="relative mb-4">
                                <div className="flex items-center relative">
                                    <InputBox
                                        inputLabel="Type free text answer"
                                        inputValue={freeTypeAnswer}
                                        onChange={(e) => setFreeTypeAnswer(e.target.value)}
                                        multiline='true'
                                        className="block w-max p-2 pr-14 border border-gray-300 rounded-md"
                                    />
                                    <label className="cursor-pointer">
                                        <AttachFile />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => setFreeTypeImage(e.target.files[0])}
                                            className="hidden"
                                        />
                                    </label>
                                </div>
                            </div>
                            {freeTypeImage && (
                                <div className="flex items-center mb-4">
                                    <img src={URL.createObjectURL(freeTypeImage)} alt="free type answer" className="w-32 h-32 object-cover rounded-md" />
                                </div>
                            )}
                        </div>
                    )}

                    {api_res_status && (
                        <p className="text-red-700 mb-4 text-center">{api_res_status}</p>
                    )}

                    <div className="flex justify-between space-x-4">
                        <Button onClick={resetForm} variant="outlined" color="secondary">
                            Reset
                        </Button>
                        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditQuestion;
