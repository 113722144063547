import React, { useState } from 'react';
import './App.css';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { Button, IconButton, TextField } from '@mui/material';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';

function Add({ pageHeading, handleclose, setStatus, setRecentformData, formfieldNames = [], tableName }) {
    const [message, setMessage] = useState('');
    const api_url = process.env.REACT_APP_API_URL;
    console.log(formfieldNames)
    const formik = useFormik({
        initialValues: formfieldNames.reduce((acc, element) => {
            acc[element] = '';
            return acc;
        }, {}),
        onSubmit: async (values) => {
            console.log(values);
            const payload = {
                tableName,
                data: values
            };
            await sendDataToserver(payload);
        },
    });
    console.log(setRecentformData)

    const sendDataToserver = async (formData) => {

        try {
            const res = await fetch(`${api_url}/manage/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            const result = await res.json();
            console.log(result);
            setMessage(result.message);

            if (res.ok) {
                console.log('Data inserted');
                setStatus(true);
                setRecentformData(formData);
            } else {
                console.error('Error while insertion');
            }
        } catch (error) {
            console.error('Network error:', error);
            setMessage('Network error occurred.');
        }
    };

    console.log(message);

    return (
        <div className='modal-overlay mx-auto'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md  '>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <h2 className='text-xl text-center'>Add {pageHeading}</h2>
                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>
                <form className='flex flex-col  px-5 mb-5 gap-10' onSubmit={formik.handleSubmit}>
                    {formfieldNames.map((item, index) => (
                        <div key={index} className='flex flex-col'>

                            <InputBox
                                required='true'
                                inputLabel={item}
                                inputId={'add'}
                                onChange={formik.handleChange}
                                inputValue={formik.values[item]}
                                type={'text'}
                                inputName={item}

                            />
                        </div>
                    ))}
                    {message && <div id='status' className='text-yellow-300'>{message}</div>}
                    <Button variant='contained' id='Save' type='submit'>Save</Button>
                </form>
            </div>
        </div>
    );
}

export default Add;
