import { Button } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

function SaveExamData() {
    const [assessmentAttemptId, setassessmentAttempId] = useState()
    const location = useLocation()
    const navigate = useNavigate();
    const [Error, SetError] = useState(false)
    const data = location.state?.data;
    console.log(data)
    // useEffect(() => {
    //     const handlePopState = (event) => {
    //         event.preventDefault();
    //         navigate(window.location.pathname); // Redirect to the same page to prevent back navigation
    //     };

    //     // Push a new entry to the history stack
    //     window.history.pushState(null, '', window.location.href);

    //     // Add the event listener for the popstate event
    //     window.addEventListener('popstate', handlePopState);

    //     // Cleanup the event listener when the component unmounts
    //     return () => {
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, [navigate]);
    const token = localStorage.getItem('token')
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.userId;
    const assessmentAttemptData = {
        assessmentId: data,
        UserId: userId,
        assessmentDateTime: new Date(),

    }
    const GenerateAssessment = async () => {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/save`, {
            method: 'post',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(assessmentAttemptData)
        });
        const result = await response.json();
        console.log(result)

        setassessmentAttempId(result.assessmentAttemptId)


        if (!response.ok) {
            console.error("Error occurred: " + result.message);
            SetError(result.message)

        }
    };
    useEffect(() => {
        GenerateAssessment()
    }, [])

    const ToExam = () => {


        navigate(`/exam/`, { state: { data: assessmentAttemptId, assessmentId: assessmentAttemptData.assessmentId } })


    }

    return (

        <div className="flex items-center justify-center align-middle mt-7">

            {Error ? (<div className='flex justify-center items-center h-screen'>Something went wrong! Please try again </div>) : (<>
                <div>
                    <div className="bg-white p-10  rounded-lg shadow-lg w-full max-w-lg ">
                        <h2 className="text-2xl font-semibold mb-4">Pre-Exam Guidelines</h2>
                        <ul className="list-disc list-inside mb-4">
                            <li>Ensure your computer meets the exam's technical requirements.</li>
                            <li>Verify your internet connection is stable and high-speed.</li>
                            <li>Test your webcam and microphone to ensure they are working correctly.</li>
                            <li>Set up your exam space in a quiet and well-lit area.</li>
                            <li>Have your identification ready for verification if required.</li>
                            <li>Keep track of time and pace yourself during the exam.</li>
                        </ul>


                    </div>
                    <div className='flex align-middle justify-center mt-6'><Button variant='contained' onClick={ToExam}>start Assessment</Button></div>
                </div>
            </>)}
        </div>
    )
}

export default SaveExamData
