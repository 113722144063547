import React from 'react'

function Heading({ heading }) {
    return (
        <div className='text-center text-xl my-4'>
            {heading}
        </div>
    )
}

export default Heading
