import { Autocomplete, Checkbox, ListItemText, MenuItem, TextField } from '@mui/material'
import { Field } from 'formik';
import React from 'react'

export function InputBox({ customcss, autocomplete = 'off', multiline = false, onBlur, helperText, error = '', required = false, type = 'text', inputValue, inputName, onChange, inputLabel, inputCss, inputId }) {
    return (
        <TextField
            autoComplete={autocomplete}
            required={required}
            name={inputName}
            value={inputValue}
            id={inputId}
            type={type}
            onChange={(e, value) => onChange(e, value)}
            label={inputLabel}
            variant="standard"
            helperText={helperText}
            error={error}
            onBlur={onBlur}
            multiline={multiline}
            className='w-full '

        />
    )
}




export function InputWithDropDown({ data, dataText, dataField, inputValue, inputName, onChange, inputLabel, inputId }) {
    return (
        <Autocomplete
            id={inputId}
            options={data}
            value={inputValue}
            getOptionLabel={(option) => option[dataText]}  // What to display for each option
            className='w-full'
            isOptionEqualToValue={(option, value) => option[dataField] === value[dataField]}
            onChange={(event, value) => onChange(event, value)}
            renderInput={(params) => (
                <TextField

                    {...params}
                    label={inputLabel}
                    variant="standard"
                />
            )}
        />
    )
}


export function CustomSelect({ name, label, dataText, dataField, options = [], value, onChange, }) {
    return (
        <TextField
            id={name}
            name={name}
            select
            value={value}
            label={label}
            onChange={(e, value) => onChange(e)}
            variant='standard'
            className='w-full'
            slotProps={{
                select: {
                    native: true,
                },
            }}
        >
            {options.map((option) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {option[dataText]}
                </MenuItem>
            ))}
        </TextField>
    );
};





export function CustomSelectv2({
    name,
    label,
    dataText,
    dataField,
    options = [],
    value = [],
    onChange,
    multiple = false,
    inputId,
    Blur,
    error,
    helperText
}) {
    const handleChange = (event) => {
        const { value } = event.target;
        onChange(multiple ? value : [value]); // Handle multiple or single selection based on the `multiple` prop
    };

    return (
        <TextField
            id={inputId}
            name={name}
            select
            value={value}
            label={label}
            onChange={handleChange}
            variant="standard"
            onBlur={Blur}
            error={error}
            helperText={helperText}
            className="w-full"
            SelectProps={{
                multiple, // Enable multi-select functionality
                renderValue: (selected) =>
                    options
                        .filter(option => selected.includes(option[dataField]))
                        .map(option => option[dataText])
                        .join(', '),
            }}
        >
            {options.map((option) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {multiple && (
                        <Checkbox
                            checked={value.includes(option[dataField])}
                        />
                    )}
                    <ListItemText primary={option[dataText]} />
                </MenuItem>
            ))}
        </TextField>
    );
}



