


import React, { useEffect, useState } from 'react'
import ManageModule from './ManageReuseble/ManageModule'
import CircularProgress from '@mui/material/CircularProgress';


function Menus() {
    const [Data, setData] = useState('')
    const [recentData, setRecentformData] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [datakeys, setdatakeys] = useState([]) // table heading names without id , if you want to show all columns , pass it in tableheadings
    const tableName = "staticmenus"

    console.log(insertionStatus)


    useEffect(() => {
        if (insertionStatus) {
            setInsertionStatus(false);
            getData()
        }
    }, [insertionStatus, recentData]);
    const getData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData   `, {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: tableName })

        })
        const result = await response.json();
        if (response.ok) {
            setData(result.data)
            setdatakeys(Object.keys(result.data[0]).slice(1))
        }
    }
    console.log(Data)
    useEffect(() => {
        getData()
    }, [])

    const columns = [
        { header: 'Menu', field: 'Menu' },
        { header: 'Description', field: 'Description' },
        { header: 'Route Path', field: 'RoutePath' },
        { header: 'Status', field: 'StatusId' },

    ];
    return (
        <div>
            {
                Data?.length && datakeys?.length > 0
                    ? <ManageModule
                        pageHeading='Menu List'
                        permissionprefix='Menu'
                        data={Data}
                        tableHeadings={datakeys}  // can give table heading  name in array too eg ['h1','h5']
                        tableName={tableName}
                        formfieldNames={datakeys} // can gives fields name in array too. eg. ['column1','column5']
                        setRecentformData={setRecentformData}
                        setInsertionStatus={setInsertionStatus}
                        columns={columns}
                    />
                    :
                    <p className='flex justify-between items-center h-screen'><CircularProgress /></p>
            }



        </div>
    )
}

export default Menus
