import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Candidates from './components/candidates/Candidates';
import Skills from './components/skills/Skills';
import Competency from './components/competency/Competency';
import JobRole from './components/jobrole/JobRole';
import CompetencySkills from './components/competencySkills/CompetencySkills';
import Header from './components/navigation/Header';
import Jobrolecompetency from './components/jobrolecompetency/Jobrolecompetency';
import Questionsetter from './components/question/CreateQuestion';
import Showquestions from './components/question/questionPreview/Showquestions';
import CreateAssessment from './components/assessment/CreateAssessment';
import Exam from './components/question/questionAttemp/Exam';
import SaveExamData from './components/question/questionAttemp/SaveExamData';
import PageNotFound from './components/reusableComp/PageNotfound';
import ScoreSummerySheet from './components/question/questionAttemp/ScoreSummerySheet';
import Signup from './components/authentication/Signup';
import Login from './components/authentication/Login';
import ProtectedRoute from './components/reusableComp/ProtectedRoute';
import Unauthorised from './components/reusableComp/Unauthorised';
import ManageUser from './components/Manage/ManageUsers';
import ManageUserType from './components/Manage/ManageUserType';
import Role from './components/Manage/Roles';
import Permissions from './components/Manage/Permissions';
import Menus from './components/Manage/Menus';
import Status from './components/Manage/Status';
import ManageSkills from './components/Manage/ManageSkills'
import Home from './components/Home/Home'
import ProfileFilter from './components/assessment/newfeatures/ProfileFilter';
import AssesmentResults from './components/assessment/newfeatures/AssessmentResults';
import ShowCharts from './components/assessment/newfeatures/ChartView/ShowCharts';
import EditAssessment from './components/assessment/EditAssessment';
import CreateQuestion from './components/question/CreateQuestion';
import ManageQuestions from './components/question/ManageQuestions';
import EditQuestion from './components/question/EditQuestion';
import ManageAssessments from './components/assessment/ManageAssessments';
import ManageRoleMenus from './components/Manage/ManageRoleMenus/ManageRoleMenus';
import ManageRolePermission from './components/Manage/ManageRolePermissions/ManageRolePermission';
import ManageUserRoles from './components/Manage/ManageUserRole/ManageUserRole';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<ProtectedRoute element={Home} />} />
        <Route path="manageskills" element={<ProtectedRoute element={ManageSkills} />} />
        <Route path="managecompetency" element={<ProtectedRoute element={Competency} />} />
        <Route path="managejobrole" element={<ProtectedRoute element={JobRole} />} />
        <Route path="comp_skills" element={<ProtectedRoute element={CompetencySkills} />} />
        <Route path="jobrole_competency" element={<ProtectedRoute element={Jobrolecompetency} />} />
        <Route path="createQuestion" element={<ProtectedRoute element={CreateQuestion} />} />
        <Route path="previewQuestion" element={<ProtectedRoute element={Showquestions} />} />
        <Route path="createAsssessment" element={<ProtectedRoute element={CreateAssessment} />} />
        <Route path="exam" element={<ProtectedRoute element={Exam} />} />
        <Route path="ManageAssessments" element={<ProtectedRoute element={ManageAssessments} />} />
        <Route path="assessment" element={<ProtectedRoute element={SaveExamData} />} />
        <Route path="ScoreSummery" element={<ProtectedRoute element={ScoreSummerySheet} />} />
        <Route path="SignUp" element={<Signup />} />
        <Route path="Login" element={<Login />} />
        <Route path="unauthorise" element={<Unauthorised />} />
        <Route path="manageuserType" element={<ProtectedRoute element={ManageUserType} />} />
        <Route path="manageRoles" element={<ProtectedRoute element={Role} />} />
        <Route path="managePermissions" element={<ProtectedRoute element={Permissions} />} />
        <Route path="manageMenus" element={<ProtectedRoute element={Menus} />} />
        <Route path="manageStatus" element={<ProtectedRoute element={Status} />} />
        <Route path="manageUsers" element={<ProtectedRoute element={ManageUser} />} />
        <Route path="profileFilter" element={<ProtectedRoute element={ProfileFilter} />} />
        <Route path="assesmentResults" element={<ProtectedRoute element={AssesmentResults} />} />
        <Route path="AssessmentReport" element={<ProtectedRoute element={ShowCharts} />} />
        <Route path="EditAssessment" element={<ProtectedRoute element={EditAssessment} />} />
        <Route path="manageQuestions" element={<ProtectedRoute element={ManageQuestions} />} />
        <Route path="editQuestion" element={<ProtectedRoute element={EditQuestion} />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="ManageRoleMenu" element={<ProtectedRoute element={ManageRoleMenus} />} />
        <Route path="ManageRolePermission" element={<ProtectedRoute element={ManageRolePermission} />} />
        <Route path="UserRoles" element={<ProtectedRoute element={ManageUserRoles} />} />


      </Routes>
    </Router>
  );
}

export default App;
