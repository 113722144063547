import React, { useEffect, useState } from 'react';
import './exam.css';
// import data from './Data';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, StarBorder } from '@mui/icons-material';
import { json, useLocation, useNavigate, useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import ExamFinishModal from './ExamFinishModal';

function Exam() {
    const [allQuestionData, setAllQuestionData] = useState([]);
    const [timeLeft, setTimeLeft] = useState();
    const [assessmentDetails, setAssessmentDetails] = useState('')
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState();
    const [answersRecord, setAnswersRecord] = useState([]);
    // const [status, setStatus] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [currentQuestion, setcurrentQuestion] = useState()
    const [previousIndex, setpreviousIndex] = useState();
    const [TextAnswerLocalValue, setTextAnswerLocalValue] = useState()
    const [ExamFinished, setExamFinished] = useState(false)
    const navigate = useNavigate();
    // useEffect(() => {
    //     const handlePopState = (event) => {
    //         event.preventDefault();
    //         navigate(1); // Prevent back navigation by forcing forward navigation
    //     };

    //     window.history.pushState(null, '', window.location.href);
    //     window.addEventListener('popstate', handlePopState);

    //     return () => {
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, [navigate]);




    useEffect(() => {
        if (currentQuestion?.questionType == 'free') {
            setTextAnswerLocalValue(answersRecord[currentQuestion.questionId]?.Answer || "")
        }
    }, [currentQuestion])
    const location = useLocation();
    const idOfassessmentAttempt = location?.state?.data;
    const assessmentId = location?.state?.assessmentId;




    const getQuestions = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/getQuestions`, {
            method: 'post',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({ assessmentAttemptId: idOfassessmentAttempt })
        });
        const result = await response.json();
        console.log(result);

        // Set all questions data
        setAllQuestionData(result.savedQuestions);
        setCurrentQuestionIndex(result.assessmentDetails.currentQuestion)

        // setcurrentQuestion(result.assessmentDetails.)
        const remainingTime = (calculateRemainingTime((result.assessmentDetails?.AssessmentDateTime), (result.assessmentDetails?.Time)))
        setTimeLeft(remainingTime?.minutes)
        console.log(remainingTime)
        setAssessmentDetails(result.assessmentDetails)
        console.log((result.assessmentDetails?.currentQuestion))
        // settingCurrentQuesiotn(result.assessmentDetails?.currentQuestion);


        // Create a new answers record object
        const newAnswersRecord = {};

        result.savedQuestions?.forEach(question => {
            result.answers.forEach(item => {
                if (item.AssessmentAttemptQId === question.AssessmentAttemptQId) {

                    const currentAnswer = Array.isArray(newAnswersRecord[question.questionId]?.Answer)
                        ? newAnswersRecord[question.questionId].Answer : [];

                    const updatedAnswer = [...currentAnswer, item.answer]

                    // Initialize Answer as an array if the question type is 'mcq-multiple'
                    const isMcqMultiple = question.questionType == 'mcq-multiple'; // or use the correct type identifier
                    newAnswersRecord[question.questionId] = {
                        Answer: isMcqMultiple ? updatedAnswer : item.answer,
                        status: item.status,
                        questionId: question.questionId,
                        assessmentAttemptQId: question.AssessmentAttemptQId, // Ensure consistent casing
                    };
                }
            });
        })

        // Set the accumulated answers record in one go
        setAnswersRecord(newAnswersRecord);

        // Set the initial time left from the first question's time



    };




    useEffect(() => {
        getQuestions()
    }, [])

    function calculateRemainingTime(startTime, durationInMinutes) {
        // Convert startTime from ISO format to a Date object
        const startDate = new Date(startTime);

        // Convert duration from minutes to milliseconds
        const durationInMilliseconds = durationInMinutes * 60 * 1000;

        // Get the current time
        const currentTime = new Date();

        // Calculate the elapsed time in milliseconds
        const elapsedTime = currentTime - startDate;

        // Calculate the remaining time in milliseconds
        const remainingTime = Math.max(0, durationInMilliseconds - elapsedTime);

        // Convert remaining time to minutes and seconds
        const remainingMinutes = Math.round(remainingTime / (1000 * 60));
        const remainingSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        return {
            minutes: remainingMinutes,
            seconds: remainingSeconds,
            timeInMilliseconds: remainingTime
        };
    }

    // Example usage:


    //pagination logic
    const questionsPerPage = 20;
    const totalPages = Math.ceil(allQuestionData?.length / questionsPerPage);
    const firstQuestionIndex = ((currentPage - 1) * questionsPerPage);
    console.log('firstQuestionIndex' + " " + firstQuestionIndex)
    const currentPageQuestions = allQuestionData?.slice(firstQuestionIndex, firstQuestionIndex + questionsPerPage);


    // Handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };


    useEffect(() => {
        console.log("current page is changed")
        setcurrentQuestion(currentPageQuestions.find(item => (item.QSequenceNo) == currentQuestionIndex));
        console.log(currentPageQuestions.find(item => item.QSequenceNo == currentQuestionIndex));        // saveCurrentquestion(currentQuestion?.QSequenceNo)
        // saveCurrentquestion()
        console.log("from here")
        console.log(currentQuestionIndex)
        console.log(currentQuestion)
    }, [currentQuestionIndex, currentPage, allQuestionData]);


    // useEffect(() => {
    //     console.log("currentQusitonindex changed to" + currentQuestionIndex)
    //     setcurrentQuestion(currentPageQuestions.find(item => (item.QSequenceNo) == currentQuestionIndex));

    // }, [currentQuestionIndex])


    // current question change
    const goToQuestion = (index) => {
        console.log(index)
        setpreviousIndex(currentQuestionIndex);
        setCurrentQuestionIndex(index)
        saveCurrentquestion(currentQuestion?.QSequenceNo);


        // Calculate new page index based on the new question index
        const newPage = Math.ceil((index + 1) / questionsPerPage);
        setCurrentPage(newPage);
    };


    // handling changeds


    const handleAnswerChange = (questionId, answer, questionAttemptId, Type) => {
        console.log(questionId, answer)
        if (!answer) {
            return
        }

        if (Type == 'mcq' || Type == 'free') {

            setAnswersRecord((prevAnswers) => ({
                ...prevAnswers,
                [questionId]: {
                    QuestionId: questionId,
                    Answer: answer,
                    questionIndex: currentQuestionIndex,
                    assessmentattemptQId: questionAttemptId,
                    status: answer.length > 0 ? 'answered' : 'notAnswered',

                },
            }));
            const data = {
                Answer: answer,
                QuestionId: questionId,
                status: 'answered',
                AssessmentAttempQId: questionAttemptId,
                questionType: 'mcq'
            };
            saveAnswers(data);
        }
        else if (Type == "mamcq") {
            const currentAnswer = Array.isArray(answersRecord[questionId]?.Answer)
                ? answersRecord[questionId].Answer
                : [];
            const updatedAnswer = currentAnswer.map(String).includes(String(answer))
                ? currentAnswer.filter(item => String(item) !== String(answer))
                : [...currentAnswer, answer];
            // first check if option is removed or added

            setAnswersRecord((prevAnswers) => ({
                ...prevAnswers,
                [questionId]: {
                    QuestionId: questionId,
                    questionIndex: currentQuestionIndex,
                    assessmentattemptQId: questionAttemptId,
                    status: updatedAnswer.length > 0 ? 'answered' : 'notAnswered',
                    Answer: updatedAnswer
                },
            }));
            const data = {
                Answer: updatedAnswer,
                QuestionId: questionId,
                status: updatedAnswer.length > 0 ? 'answered' : 'notAnswered',
                AssessmentAttempQId: questionAttemptId,
                questionType: 'mamcq'
            };
            console.log(data)
            saveAnswers(data);
        }




    };

    // mark for review
    const markForReview = async (questionId) => {


        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/markedForReview`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ AssessmentAttemptQId: currentQuestion.AssessmentAttemptQId })
        })
        const result = await response.json()
        console.log(result)
        if (response.ok) {

            setAnswersRecord((prev) => ({
                ...prev,
                [questionId]: {
                    ...prev[questionId],

                    status: "markedForReview"
                }
            }));

        }



    };


    const skipQuestion = async () => {
        console.log('skip question  ')
        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/skipped`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ AssessmentAttemptQId: currentQuestion.AssessmentAttemptQId })
        })
        const result = await response.json()
        console.log(result)
        if (response.ok) {

            setAnswersRecord((prev) => ({
                ...prev,
                [currentQuestion.questionId]: {
                    ...prev[currentQuestion.questionId],

                    status: "skipped"
                }
            }));

        }

        setCurrentQuestionIndex(prevIndex => Math.min(prevIndex + 1, allQuestionData.length - 1));
        saveCurrentquestion(currentQuestion?.QSequenceNo);
    };




    // when page changes

    // clear inputs
    const clearAllInput = async () => {
        setAnswersRecord((prev) => ({
            ...prev,
            [currentQuestion.questionId]: {
                ...prev[currentQuestion.questionId],
                Answer: '',
                status: "notAnswered"
            }
        }));
        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/clearResponce`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ AssessmentAttemptQId: currentQuestion.AssessmentAttemptQId })
        })
        const result = await response.json()
        console.log(result)
        if (response.ok) {
            if (result.affectedRows > 0) {
                setAnswersRecord((prev) => ({
                    ...prev,
                    [currentQuestion.questionId]: {
                        ...[currentQuestion.questionId],
                        Answer: '',
                        status: "notAnswered"
                    }
                }));
            }
        }





    };

    const saveCurrentquestion = async (questionSecNo) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/saveCurrentquestion`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ QSequenceNo: questionSecNo, AssessmentAttemptId: assessmentDetails.AssessmentAttemptId })
        })


    }

    useEffect(() => {
        console.log(currentQuestionIndex)
        saveCurrentquestion(currentQuestionIndex)
    }, [currentQuestionIndex])
    // handling timer
    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setInterval(() => {
                setTimeLeft(prevTimeLeft => prevTimeLeft - 1 / 60);
            }, 1000);

            return () => clearInterval(timerId);
        } else {
            console.log("Time finished");
            // AssessmentSubmitted()
        }
        console.log(timeLeft);

    }, [timeLeft]);

    const formatTime = (minutes) => {
        const totalSeconds = Math.floor(minutes * 60);
        const mins = Math.floor(totalSeconds / 60);
        const secs = totalSeconds % 60;


        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };






    const saveAnswers = async (data) => {
        const responce = await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/saveAnswers`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        const result = await responce.json();
        console.log(result)
        if (!responce.ok) {
            console.log(result.message)
        }
        else {
            console.log(result)
            setAnswersRecord((prevAnswers) => ({
                ...prevAnswers,
                [currentQuestion.questionId]: {
                    QuestionId: currentQuestion.questionId,
                    Answer: result.Answer,
                    assessmentattemptQId: currentQuestion.questionAttemptId,
                    status: 'answered'

                },
            }));

        }

    }


    // assessment submit or finish
    const AssessmentSubmitted = async () => {
        console.log('submmited')
        const responce = await fetch(`${process.env.REACT_APP_API_URL}/Scoring/AssessmentSubmitted`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ flag: true, AssessmentId: assessmentId, AssessmentAttemptId: assessmentDetails.AssessmentAttemptId })
        })
        if (responce.ok) {
            setExamFinished(true)
            navigate('/ScoreSummery', { state: { data: assessmentDetails.AssessmentAttemptId } })

        }
    }

    console.log(currentPageQuestions)
    return (

        <div className="exam-container p-5">

            {
                allQuestionData ? (


                    <div>
                        <div className='flex  justify-between self-center'>
                            <div className='mx-5 my-auto font-bold '>
                                {assessmentDetails.NameOfAssessment}
                            </div>
                            <div className=' flex flex-row gap-20  m-2 p-2 items-baseline'>
                                <button className='  border-none ' onClick={() => markForReview(currentQuestion.questionId)}> {answersRecord[currentQuestion?.questionId]?.status === "markedForReview" ? <StarBorder className='border-none bg-blue-900 text-white  ' /> : <StarBorder className='border-none ' />} Mark for Review</button>

                                <div className={` px-4 py-2 w-20 border rounded-s-3xl rounded-e-3xl text-md  ${timeLeft < 4 ? 'bg-red-600 text-white' : 'bg-sky-500'}`} >   <p>{formatTime(timeLeft)}</p></div>
                            </div>
                        </div>
                        <div className="flex gap-3 py-14 QuesitonAnswerContainer justify-center lg:justify-between md:justify-between  lg:flex-row md:flex-row  flex-col border rounded-lg shadow-lg p-4 bg-gray-50">
                            <div className="question-container  flex flex-col  w-full lg:w-3/5 md:w-3/5 ">
                                <div className="flex flex-row gap-2 mb-4">
                                    <div className="font-bold text-lg">Q{currentQuestion?.QSequenceNo + 1}</div>
                                    <div className="text-gray-700">{currentQuestion?.question}</div>
                                </div>

                                <div className="options-container mb-4">
                                    {currentQuestion?.questionType?.includes('mcq') ? (
                                        currentQuestion?.optionDetails?.map((item, index) => (
                                            <div key={index} className="flex items-center mb-2">
                                                <span className='mr-3 font-semibold'>{index + 1}.</span>
                                                {currentQuestion.questionType.includes('single') ? (
                                                    <input
                                                        type='radio'
                                                        name={`question-${currentQuestion.questionId}`}
                                                        checked={answersRecord[currentQuestion.questionId]?.Answer == item.questionOptionId}

                                                        onChange={() => handleAnswerChange(currentQuestion.questionId, item.questionOptionId, currentQuestion.AssessmentAttemptQId, 'mcq')}
                                                        className="mr-2"
                                                    />


                                                ) : (
                                                    <input
                                                        type='checkbox'
                                                        name={`question-${currentQuestion.questionId}`}
                                                        checked={answersRecord[currentQuestion.questionId]?.Answer.map(String).includes(String(item.questionOptionId)) || false}

                                                        onChange={() => handleAnswerChange(currentQuestion.questionId, item.questionOptionId, currentQuestion.AssessmentAttemptQId, 'mamcq')}
                                                        className="mr-2"
                                                    />


                                                )}
                                                <span className="text-gray-600">{item.optionText} </span>
                                            </div>
                                        ))
                                    ) : (
                                        <div className='flex flex-col gap-2 justify-start'>
                                            <textarea
                                                placeholder='Write your answer here'
                                                className=' border rounded-md p-2 w-full md:w-5/6 lg:5/6 '
                                                value={TextAnswerLocalValue}
                                                onChange={(e) => setTextAnswerLocalValue(e.target.value)}
                                            />
                                            <Button variant='contained' className='w-fit items-end' onClick={() => handleAnswerChange(currentQuestion.questionId, TextAnswerLocalValue, currentQuestion.AssessmentAttemptQId, "free")}>save answer</Button >
                                        </div>
                                    )}
                                </div>

                                <div className='w-full'>
                                    <Button variant='outlined' className='clear-btn border rounded-md p-2 mt-2 bg-red-500 text-white hover:bg-red-600 transition duration-200' onClick={() => clearAllInput()}>
                                        Clear Input
                                    </Button>
                                </div>
                            </div>

                            <div className='border flex-col  rounded-lg shadow-lg mx-auto  w-3/5 lg:w-2/5 md:w-2/5 AnswerStatus  p-4'>
                                <div className='text-center font-semibold mb-2 '>Answer Status</div>
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5  gap-5 w-4/5 justify-center mx-auto">
                                    {currentPageQuestions?.map((item, index) => (
                                        <button
                                            key={index}

                                            className={`question-index justify-self-center
                            ${currentQuestion?.questionId === item.questionId && 'border-2 border-spacing-2 border-double bg-yellow  '}
                            ${answersRecord[item.questionId]?.status === 'answered' ? 'answeredStyle' : ''} 
                            ${answersRecord[item.questionId]?.status === 'notAnswered' ? 'notAnsweredStyle' : ''} 
                            ${answersRecord[item.questionId]?.status === 'null' ? 'notAnsweredStyle' : ''} 
                            ${answersRecord[item.questionId]?.status === 'markedForReview' ? 'markedForReviewStyle' : ''} 
                            ${answersRecord[item.questionId]?.status === 'skipped' ? 'skippedStyle ' : ''} 
                            transition duration-200 hover:shadow-lg w-8 rounded-3xl *:  `}
                                            onClick={() => goToQuestion(firstQuestionIndex + index)}
                                        >
                                            {firstQuestionIndex + index + 1}
                                        </button>
                                    ))}
                                </div>

                                <div className='pagination flex justify-center gap-1 mt-8'>
                                    {(currentPage > 1) && <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        className='mx-2 bg-gray-300 h-10  font-bold w-10 p-2 hover:bg-gray-400 disabled:bg-gray-200'>
                                        <KeyboardDoubleArrowLeft />
                                    </button>
                                    }
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => handlePageChange(index + 1)}
                                            className={` p-2 h-10 w-10 font-bold ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}>
                                            {index + 1}
                                        </button>
                                    ))}
                                    {(currentPage < totalPages) && <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        className=' bg-gray-300 h-10 w-10  font-bold p-2 hover:bg-gray-400 disabled:bg-gray-200'>
                                        <KeyboardDoubleArrowRight />
                                    </button>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className='buttons flex justify-center md:justify-end lg:justify-end flex-wrap gap-2 p-4'>
                            <button className='border-none rounded-s-3xl rounded-e-3xl py-2 text-white px-4 bg-gray-400  hover:bg-blue-600 transition duration-200' onClick={skipQuestion}>Skip</button>
                            <button className='border-none rounded-s-3xl rounded-e-3xl py-2 text-white  px-4 bg-blue-700 hover:bg-blue-400 transition duration-200' onClick={() => goToQuestion(Math.max(currentQuestionIndex - 1, 0))}>Previous</button>
                            <button className='border-none rounded-s-3xl rounded-e-3xl py-2 text-white px-4 bg-blue-700 hover:bg-blue-400 transition duration-200' onClick={() => goToQuestion(Math.min(currentQuestionIndex + 1, currentPageQuestions.length - 1))}>Next</button>
                            <ExamFinishModal AssessmentSubmitted={AssessmentSubmitted} />

                        </div>

                    </div>) :
                    (
                        <div className='flex justify-center items-center align-middle h-screen'><CircularProgress /></div>
                    )


            }
        </div>

    );
}

export default Exam;





