import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { CloseIconButton, DeleteIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';

function DeleteConfirmation({ action, index, itemData }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    return (
        <>
            <DeleteIconButton action={handleOpen} id={index} />
            {open && (
                <div className='modal-overlay'>
                    <div className='bg-gray-50 rounded-md w-5/6 md:w-2/6 lg:w-2/6 '>
                        <div className=' bg-sky-600 rounded-t-md  flex justify-between items-baseline h-auto'>
                            <h2 className='invisible'>heading</h2>
                            <h2 className='text-gray-50  '><Heading className='' heading={"Are You sure?"} /></h2>

                            {/* <IconButton onClick={handleClose} className='border-none mr-3'><CloseIcon /></IconButton> */}
                            <CloseIconButton className='mb-1 text-gray-50 ' action={handleClose} />

                        </div>
                        <div className='flex-col text-center  mt-4'>
                            <h2 className='text-justify text-wrap    p-5 '>Do you realy want to delete all associated menus from <em className='font-semibold'>{itemData}</em> role? </h2>

                        </div>
                        <div className='flex justify-around m-4'>
                            <Button variant='outlined' id='no' onClick={handleClose}>No</Button>
                            <Button variant='contained' id='yes' onClick={() => { action(); handleClose(); }}>Yes</Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DeleteConfirmation;