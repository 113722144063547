import React from 'react';
import QuestionCard from './QuestionCard';

const QuestionList = ({ data }) => {
    console.log(data)
    return (
        <div className="question-list space-y-4">
            {data.map((question, index) => (
                <div key={index} className="question-card">
                    <QuestionCard question={question} index={index} />
                </div>
            ))}
        </div>
    );
};

export default QuestionList;
