import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import QuestionList from './QuestionList';

function ShowQuestions() {
    const api_url = process.env.REACT_APP_API_URL;
    const [Questions, setQuestions] = useState([]);
    const [ErrorMessage, setErorMessage] = useState([])

    const location = useLocation();
    const data = location.state?.data;
    console.log(data)
    const getQuestions = async () => {
        const response = await fetch(`${api_url}/assessment_preview`, {
            method: 'post',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({ 'assessmentId': data })
        });
        const result = await response.json();
        setQuestions(result.selectedQuestions);
        if (!response.ok) {
            console.error("Error occurred: " + result.message);
            setErorMessage(result.message)
        }
    };
    console.log(Questions)
    useEffect(() => {
        getQuestions();
    }, []);

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-center font-serif text-xl m-4">Questions Preview</h1>
            {ErrorMessage && (<div className='text-red-600 text-center'>{ErrorMessage}</div>)}
            {Questions && <div className="lg:w-3/4 mx-auto bg-white p-6 rounded-lg shadow-md">
                <QuestionList data={Questions} />
            </div>}


        </div>
    );
}

export default ShowQuestions;
