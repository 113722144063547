import React from 'react';
import { Divider } from '@mui/material';
import m1 from './systemImages/m1.png'
import m2 from './systemImages/m2.png'
import m3 from './systemImages/m3.png'
import m4 from './systemImages/m4.png'
import f1 from './systemImages/f1.png'
import f2 from './systemImages/f2.png'
import f3 from './systemImages/f3.png'
import f4 from './systemImages/f4.png'
import { Link } from 'react-router-dom';


function Profile({ data }) {

    const imagePath = {
        m1: m1,
        m2: m2,
        m3: m3,
        m4: m4,
        f1: f1,
        f2: f2,
        f3: f3,
        f4: f4

    }
    console.log(data)
    return (
        <div className="bg-white p-6 rounded-lg border-2 gap-6 shadow-lg flex flex-col md:flex-row">
            {/* Profile Image */}
            <div className="flex justify-start mb-4 md:mb-0">
                { }
                <img
                    src={imagePath[data.profileImage]}
                    alt={data.profileImage}
                    className="w-20 h-20 md:w-40 md:h-40 object-cover rounded-full "
                />
            </div>

            {/* Name, Lab, Rank, Area of Interest */}
            <div className="flex flex-col w-full h-1/2 gap-2 ">
                <div className="flex   ">
                    <div className="text-blue-700 font-bold p-2 tracking-wider "><a href="Insights Develop Report_Sample.pdf"
                        download="report.pdf" >{data.name}</a> </div>
                    <Divider orientation="vertical" flexItem />  <div className=" p-2 text-blue-600">{data.lab}</div>
                    <Divider orientation="vertical" flexItem />  <div className="text-blue-600 p-2">{data.rank}</div>
                    <Divider orientation="vertical" flexItem />  <div className="text-blue-600 p-2">{data.areaOfInterest}</div>
                </div>
                <hr />
                {/* Competencies List */}
                <div className="flex flex-wrap gap-2 mt-2 h-1/2">
                    {data.competencies.map((item, index) => (
                        <span className="p-2 text-gray-600 border-2 "> {item.competency}:<span className='text-blue-700 mx-1'>{item.score}</span></span>

                    ))

                    }
                </div>
            </div>
        </div>
    );
}

export default Profile;
