import React from 'react';
import { useNavigate } from 'react-router-dom';
function Header() {





    return (
        <div>
            <div className='w-full h-fit fixed bg-white  z-50'>
                <h1 className='text-2xl text-center p-2'>Wise Assessment</h1>
            </div>

        </div>
    );
}

export default Header;
