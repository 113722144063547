import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function StatusModal({ message }) {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (message) {
            setOpen(true);
            const timer = setTimeout(() => {
                setOpen(false);
            }, 5000);

            return () => clearTimeout(timer); // Clear timeout if component unmounts or message changes
        }
    }, [message]); // Only depend on `message`

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <div>{message}</div>
                        <div><Button variant='outlined' onClick={handleClose}>Ok</Button></div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
