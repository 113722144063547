import { jwtDecode } from "jwt-decode";

const token = localStorage.getItem('token');

let permissions = [];

if (token) {
    try {
        const decodedToken = jwtDecode(token);
        permissions = decodedToken.permissions || [];
    } catch (error) {
        console.error("Invalid token or decoding error:", error);
        permissions = [];
    }
} else {
    console.warn("No token found in localStorage");
    permissions = [];
}

export const hasPermission = (requiredPermission) => {
    return permissions.some(permission =>
        permission.trim().toLowerCase() === requiredPermission.trim().toLowerCase()
    );
};

export const filterActionsByPermission = (actions) => {
    return actions.filter(action =>
        hasPermission(action.requiredPermission)
    );
};
