import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const TableComp = ({ data = [], columns, actions }) => {
    let rowsPerPage = 10
    const [page, setPage] = useState(1);

    // Handle page change
    const handleChange = (event, value) => {
        setPage(value);
    };
    // Calculate the data to display based on pagination
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = data?.slice(startIndex, endIndex);

    useEffect(() => {
        const totalPages = Math.ceil(data.length / rowsPerPage);
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages); // Move to the last valid page
        } else if (data.length === 0) {
            setPage(1); // Reset to page 1 if no data
        }
    }, [data, page, rowsPerPage]);

    console.log(actions)
    return (
        <div className="overflow-x-auto">
            {paginatedData?.length > 0 ? (
                <>
                    <table className="w-full border-collapse border border-gray-200">
                        <thead>
                            <tr className="text-gray-50" style={{ backgroundColor: '#6495ED' }}>
                                {columns?.map((col, index) => (
                                    <th key={index} className="border p-2">
                                        {col.header}
                                    </th>
                                ))}
                                {actions.length > 0 && <th className="border p-2 w-fit">Action</th>
                                }                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData?.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    {columns.map((col, colIndex) => (
                                        <td key={colIndex} className="border p-2">
                                            {item[col.field]}
                                        </td>
                                    ))}
                                    {actions.length > 0 && <td className="border p-2 w-24">
                                        <div className="flex justify-around">
                                            {actions.map((action, actionIndex) => {
                                                const ButtonComponent = action.ButtonComponent;
                                                return (
                                                    <ButtonComponent
                                                        key={actionIndex}
                                                        action={() => action.handler(item, index)}
                                                        tooltip={action.tooltip}
                                                        id={index}
                                                        itemName={Object.values(item)[1]}
                                                        itemData={item}
                                                        label={action.label}
                                                        className={action.className}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </>
            ) : (
                <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
                    <p className="text-lg font-semibold">No Data Found</p>
                </div>
            )}
            <div className="flex justify-center mt-4">
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(data.length / rowsPerPage)}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                    />
                </Stack>
            </div>
        </div>
    );
};

TableComp.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
    })).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        handler: PropTypes.func.isRequired,
        tooltip: PropTypes.string,
        className: PropTypes.string,
        ButtonComponent: PropTypes.elementType.isRequired, // Custom button component
    })).isRequired,
};



export default TableComp;
