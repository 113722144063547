import React from 'react';
import { ResponsiveRadar } from '@nivo/radar';

const radarChartData = {
    categories: ["Leadership", "Team Collaboration", "Innovation", "Project Management", "Communication"],
    targetScores: [8, 7, 6, 8, 7, 6, 8],
    achievedScores: [6, 5, 4, 7, 6, 5, 7]
};

const RadarChart = () => {
    // Prepare the data for Nivo Radar
    const data = radarChartData.categories.map((category, index) => ({
        category,
        Target: radarChartData.targetScores[index],
        Achieved: radarChartData.achievedScores[index]
    }));

    return (
        <div className='w-full  bg-slate-50' style={{ height: 400, width: 550, margin: '0 auto', padding: '20px', borderRadius: '8px' }}>
            <ResponsiveRadar
                data={data}
                keys={['Target', 'Achieved']}
                indexBy="category"
                maxValue="auto"
                margin={{ top: 50, right: 80, bottom: 70, left: 80 }}
                curve="linearClosed"
                borderWidth={3}  // Thicker lines
                borderColor={{ from: 'color' }}
                gridShape="circular"
                gridLabelOffset={36}
                enableDots={true}
                dotSize={12}  // Larger dots
                dotColor={{ theme: 'background' }}
                dotBorderWidth={3}  // Thicker dot borders
                dotBorderColor={{ from: 'color' }}
                enableDotLabel={true}
                dotLabel="value"
                dotLabelYOffset={-12}
                colors={{ scheme: 'nivo' }}
                fillOpacity={0.25}
                blendMode="multiply"
                animate={true}
                motionConfig="wobbly"
                isInteractive={true}
                legends={[
                    {
                        anchor: 'bottom-left',
                        direction: 'row',
                        translateX: -30,
                        translateY: -40,
                        itemWidth: 80,
                        itemHeight: 40,
                        itemTextColor: '#555',
                        symbolSize: 12,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    );
};

export default RadarChart;
