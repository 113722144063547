import React, { useEffect, useState } from 'react';
import DonutChartsRow from './DonutChartsRow';
import RadarChart from './RadarChart';
import SkillsTable from './SkillsTable';
import DownloadReport from '../DownlodReport';
import Competency from '../../../competency/Competency';
import { useLocation } from 'react-router-dom';

function ShowCharts() {
    const [chartData, setChartData] = useState([])

    const location = useLocation()
    console.log(location.state)
    const AssessmentAttemptId = location.state?.data?.AssessmentAttemptId;

    const getChartData = async () => {
        let result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentResults/chartsData/?id=${AssessmentAttemptId}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
        });
        result = await result.json();
        setChartData(result.data);

    }

    useEffect(() => {
        getChartData()
    }, [])




    const uniqueCompetencyQuestions = new Set();
    const Cresult = chartData.filter(item => {
        const pair = `${item.competencyId}-${item.questionId}`;

        if (!uniqueCompetencyQuestions.has(pair)) {
            uniqueCompetencyQuestions.add(pair);
            return true;
        }

        return false;
    });




    const CompetencyData = [];
    Cresult.forEach(item => {
        const competencyIndex = CompetencyData.findIndex(cd => cd.competencyId === item.competencyId);
        if (competencyIndex === -1) {
            CompetencyData.push({
                competencyId: item.competencyId,
                competencyName: item.competencyName,
                occurrence: 1,

                NumberOfQuestion: item.numberOfQuestions,
                obtainedMarks: item.Score || 0,
                TotalMarks: item.TotalMarks
            });
        } else {
            CompetencyData[competencyIndex].occurrence += 1;
            CompetencyData[competencyIndex].obtainedMarks += item.Score || 0;

        }
    });

    const updateCompetencyData = CompetencyData.map((item) => {
        return {
            ...item,
            obtainedMarks: item.obtainedMarks * (item.NumberOfQuestion / item.TotalMarks),
            TotalMarks: item.occurrence * (item.NumberOfQuestion / item.TotalMarks)
        };
    })


    const uniqueCompetenySkills = new Set();
    const Sresult = chartData.filter(item => {
        const pair = `${item.skillId}-${item.questionId}`;

        if (!uniqueCompetenySkills.has(pair)) {
            uniqueCompetenySkills.add(pair);
            return true;
        }

        return false;
    });

    console.log(Sresult)

    const SkillData = []

    Sresult.forEach(item => {
        const SkillIndex = SkillData.findIndex(sd => sd.skillId == item.skillId);

        if (SkillIndex === -1) {
            SkillData.push({
                skillId: item.skillId,
                skillName: item.skillName,
                occurrence: 1,

                NumberOfQuestion: item.numberOfQuestions,
                obtainedMarks: item.Score || 0,
                TotalMarks: item.TotalMarks
            });
        } else {
            SkillData[SkillIndex].occurrence += 1;
            SkillData[SkillIndex].obtainedMarks += item.Score || 0;
        }
    });

    console.log(SkillData)


    const updateSkillData = SkillData.map((item) => {
        return {
            ...item,
            obtainedMarks: item.obtainedMarks * (item.NumberOfQuestion / item.TotalMarks),
            TotalMarks: item.occurrence * (item.NumberOfQuestion / item.TotalMarks)
        }
    })





    return (
        <div className="flex flex-col gap-2">
            <div className='text-center text-xl'>Assessment Report</div>
            <div className='flex justify-end'><DownloadReport /></div>




            <div>
                <DonutChartsRow Chartdata={updateCompetencyData} />
            </div>

            <div className="flex flex-row justify-between gap-2 ">
                <div className="">
                    <SkillsTable tableData={updateSkillData} />
                </div>
                <div className=" w-fit ">
                    <RadarChart />
                </div>

            </div>
        </div>
    );
}

export default ShowCharts;
