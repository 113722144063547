import { DownloadOutlined } from '@mui/icons-material';
import React from 'react';

const DownloadReport = () => {
    return (
        <div>
            <a
                href="Insights Develop Report_Sample.pdf"
                download="report.pdf"
                title="Download Report"
                className="flex items-center justify-center m-4 -mt-8 underline text-sm "
            >
                Downlod Detailed Report
            </a>
        </div>
    );
};

export default DownloadReport;
