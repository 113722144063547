import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Button, useMediaQuery } from '@mui/material';
import { json, Link, useNavigate } from 'react-router-dom';
import { PowerSettingsNewOutlined } from '@mui/icons-material';
import Header from './Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    marginLeft: 0,
                },
            },
        ],
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: `${drawerWidth}px`,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function NavigatorDrawer({ element: Element }) {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [UserRole, setUserRole] = useState([])
    const navigate = useNavigate()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Detect screens md and below
    const [RoutesData, setRoutesData] = useState([])
    React.useEffect(() => {
        if (isSmallScreen) {
            setOpen(false);
        }
    }, [isSmallScreen]);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const routes = [
        { path: "", MenuText: "Home", roles: ["Candidate", "Author", "SuperAdmin"] },
        { path: "manageskills", MenuText: " Manage Skills", roles: ["Author", "SuperAdmin"] },
        { path: "managecompetency", MenuText: "Manage Competencies", roles: ["Author", "SuperAdmin"] },
        { path: "managejobrole", MenuText: "Manage JobRole", roles: ["Author", "SuperAdmin"] },
        { path: "ManageAssessments", MenuText: "Manage Assessment", roles: ["Candidate", "Author", "SuperAdmin"] },
        { path: "manageuserType", MenuText: "Manage User Type", roles: ["SuperAdmin"] },
        { path: "manageRoles", MenuText: "Manage Roles", roles: ["SuperAdmin"] },
        { path: "managePermissions", MenuText: "Manage Permissions", roles: ["SuperAdmin"] },
        { path: "manageMenus", MenuText: "Manage Menus", roles: ["SuperAdmin"] },
        { path: "manageStatus", MenuText: "Manage Status", roles: ["SuperAdmin"] },
        { path: "manageUsers", MenuText: "Manage users", roles: ["SuperAdmin"] },
        { path: "assesmentResults", MenuText: "Assessment Results", roles: ["Candidate", "Author", "SuperAdmin"] },
        { path: "profileFilter", MenuText: "Profile Filter", roles: ["Author", "SuperAdmin"] },
        { path: "manageQuestions", MenuText: "Manage Questions", roles: ["Author", "SuperAdmin"] },
    ];
    const getUserRoles = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.log('Token not found');
                return;
            }

            let userId;
            try {
                const decodedToken = jwtDecode(token);
                console.log(decodedToken)
                userId = decodedToken.userId; // Assuming userId is a claim in the token
            } catch (error) {
                console.error('Invalid token:', error);
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/manage/userRoleMenu`, {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ 'UserId': userId })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result)
            setUserRole(result.data[0]);

        } catch (error) {
            console.error("Error occurred:", error);
        }
    };

    useEffect(() => {
        getUserRoles();
    }, []);

    const accessibleRoutes = routes.filter(route => route.roles.includes(UserRole.Role));
    console.log(accessibleRoutes)

    const logout = () => {
        localStorage.removeItem('token')
        navigate('/login')

    }


    const getRoutes = async () => {


        const result = await fetch(`${process.env.REACT_APP_API_URL}/getMenuitems/?userId=${localStorage.getItem('userId')}`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        })
        const response = await result.json()
        console.log(response)
        if (result.ok) {
            console.log("here")
            setRoutesData(response.data)
        }

    }

    useEffect(() => {
        getRoutes()
    }, [])
    console.log(RoutesData)
    return (

        <Box sx={{ display: 'flex' }}>

            <CssBaseline />
            <AppBar className='h-0' open={open}>
                <Toolbar className='border-b-2  flex justify-between ' style={{ backgroundColor: '#6495ED' }} >

                    <IconButton

                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={[
                            {
                                mr: 2,

                            },
                            open && { visibility: 'hidden' },
                        ]}
                    >
                        <MenuIcon className='text-gray-700' />
                    </IconButton>

                    <div>
                        <h1 className='text-2xl text-gray-50 text-center font-bold tracking-wider p-2'>Wise Assessment</h1>

                    </div>

                    <div className=''>
                        <IconButton className='border-none' onClick={logout} title='logout'><PowerSettingsNewOutlined className='text-gray-50 font-extralight' /></IconButton>

                    </div>

                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader >
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <List>
                    <Box sx={{ width: 250 }} role="presentation" >

                        <List>
                            {RoutesData?.map((item, index) => (
                                <ListItem key={index} disablePadding className='hover:bg-slate-100'>
                                    <Link className='p-2 ml-3 ' to={`/${item.RoutePath}`} >{item.Menu}</Link>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                    </Box >
                </List>

            </Drawer>
            <Main open={open}>
                <DrawerHeader />

                <Element />
            </Main>
        </Box>
    );
}
