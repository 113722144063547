import React, { useEffect, useState } from 'react';
import { Button, IconButton, Slider } from '@mui/material';
import ShowJobRole from './ShowJobRole';
import ShowSkills from './ShowSkills';
import Showcompetency from './Showcompetency';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddCircleOutlineOutlined, AddOutlined, RemoveCircleOutline } from '@mui/icons-material';
import Heading from '../reusableComp/Heading';
import Edit from '@mui/icons-material/Edit';
import JobRole from '../jobrole/JobRole';
import { CustomSelect, InputBox, InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';

function EditAssessment() {

    const location = useLocation();
    const data = location.state.data;
    console.log(data)
    const [name, setName] = useState(data.nameOfAssessment);
    const [assessmentBasis, setAssessmentBasis] = useState(data.assesmentBasis);
    const [numQuestions, setNumQuestions] = useState(data.numberOfQuestions);
    const [totalMarks, setTotalMarks] = useState(data.TotalMarks);
    const [timeToAttempt, setTimeToAttempt] = useState(data.Time);
    const [assessmentType, setAssessmentType] = useState(data.assessmentType);
    const [inputSet, setInputSet] = useState();
    const [orderOfQuestions, setOrderOfQuestions] = useState(data.orderOfQuestion);
    const [difficulty, setDifficulty] = useState(data.difficultyLevel);
    const [loading, setLoading] = useState(false);
    const [apiResStatus, setApiResStatus] = useState('');
    const [selectedIds, setSelectedIds] = useState(); // Consolidated IDs state
    const [assessmentId, setassessmentId] = useState();
    const [assessmentSections, setAssessmentSections] = useState([{ sectionName: '', competencyId: '' }])
    useEffect(() => {
        if (data) {
            setName(data.nameOfAssessment || '');
            setAssessmentBasis(data.assesmentBasis || '');
            setNumQuestions(data.numberOfQuestions || '');
            setTotalMarks(data.TotalMarks || '');
            setTimeToAttempt(data.Time || '');
            setAssessmentType(data.assessmentType || '');
            setOrderOfQuestions(data.orderOfQuestion || '');
            setDifficulty(data.difficultyLevel || '');
            setassessmentId(data.assessmentId || '');
            setSelectedIds(data.baseRef || []); // Update based on your logic for setting selectedIds
            setAssessmentSections(data.assessmentSections || [{ sectionName: '', competencyId: '' }]);
        }
    }, [data]);
    const navigate = useNavigate();
    const marks = [
        { value: 0, label: 'Easy' },
        { value: 50, label: 'Moderate' },
        { value: 100, label: 'Hard' }
    ];

    function valuetext(value) {
        const difficultyLevels = {
            0: 'Easy',
            50: 'Moderate',
            100: 'Hard'
        };
        return difficultyLevels[value];
    }
    useEffect(() => {
        const array = JSON.parse(data.baseRef);
        if (data.assesmentBasis == 'skills' || data.assesmentBasis == 'jobrole') {
            const filteredBaseRef = array.map(ref => ({
                id: ref.id || ref.competencyId,
                name: 'Decision Making',
                weight: ref.weight !== undefined ? ref.weight : (ref.competencyWeight || null)
            }));
            setSelectedIds(filteredBaseRef);
        } else {
            const filteredBaseRef = array.map(ref => ({
                competencyId: ref.id || ref.competencyId,
                competencyWeight: ref.weight !== undefined ? ref.weight : (ref.competencyWeight || null)
            }));
            setSelectedIds(filteredBaseRef);
        }
    }, [data]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            assessmentId: data.assessmentId,
            name,
            assessmentBasis,
            baseRef: selectedIds,
            numQuestions,
            totalMarks,
            timeToAttempt,
            assessmentType,
            inputSet,
            orderOfQuestions,
            difficulty
        };

        console.log(payload);
        let apiEndpoint = `${process.env.REACT_APP_API_URL}/assessment_get/update`;

        try {
            const response = await fetch(apiEndpoint, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await response.json();
            setassessmentId(result.assessmentId);
            setApiResStatus(result.message);

            if (response.ok) {
                setassessmentId(result.assessmentId);

            } else {
                console.log(result.error)
            }
        } catch (error) {
            setApiResStatus('An error occurred while creating the assessment.');
        } finally {
            setLoading(false);
        }
    };
    console.log(selectedIds)
    const handleAssessmentBasisChange = (e) => {

        setAssessmentBasis(e.target.value);

        // Clear selected IDs when assessment basis changes
        setSelectedIds([]);

        // Optionally, clear other related states if needed
        // setSelectedJobRoles([]);
        // setSelectedCompetencies([]);
        // setSelectedSkills([]);
    };

    const handleJobRolesChange = (jobRoles) => {
        setSelectedIds(jobRoles);
    };

    const handleCompetencyChange = (competencies) => {
        setSelectedIds(competencies);
    };

    const handleSkillChange = (skills) => {
        setSelectedIds(skills);
    };

    const previewQuestions = () => {
        navigate('/previewQuestion', { state: { data: assessmentId } });
    };


    const assessmentBasisData = [

        { label: 'Job Role Based', value: 'jobrole' },
        { label: 'Competency Based', value: 'competency' },
        { label: 'Skills Based', value: 'skills' },

    ]

    const assessmentTypeData = [
        { 'label': 'Same for all Participants', value: 'same' },
        { 'label': 'Create Set Of Assessment', value: 'set' },
        { 'label': 'Dynamic Question for each Participant', value: 'dynamic' }
    ]

    const assessmentOrderofQuestionData = [
        { label: 'same Order', value: 'same' },
        { label: 'shuffle', value: 'Shuffle Order' }
    ]
    return (
        <div className=" p-6 bg-gray-100 min-h-screen">


            <div className="lg:w-3/4 mx-auto bg-white  rounded-lg shadow-md">
                <div className='w-full bg-sky-600 py-1 rounded-t-md text-center text-gray-50  '><Heading heading={"Edit Assessment"} /></div>

                <form onSubmit={handleSubmit} className='flex flex-col p-6 gap-4'>
                    <div className="mb-4">
                        <InputBox
                            required
                            inputLabel='Name of Assessment'
                            type="text"
                            inputValue={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name of assessment"
                            className=" w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div>
                        <div className="my-4">

                            <CustomSelect
                                value={assessmentBasis}
                                onChange={(e) => handleAssessmentBasisChange(e)}
                                options={assessmentBasisData}
                                label='Assessment Basis'
                                name='value'
                                dataText='label'
                                dataField='value'


                            />
                        </div>


                        <div className='mb-4'>
                            {assessmentBasis === 'jobrole' && (
                                <ShowJobRole onJobRolesChange={handleJobRolesChange} existingData={JSON.parse(data.baseRef)} />
                            )}

                            {assessmentBasis === 'competency' && (
                                <Showcompetency onCompetenciesChange={handleCompetencyChange} existingData={selectedIds} />
                            )}

                            {assessmentBasis === 'skills' && (
                                <ShowSkills onSkillsChange={handleSkillChange} existingData={JSON.parse(data.baseRef)} />
                            )}
                        </div>
                    </div>

                    <div className="mb-4">
                        <InputBox
                            required
                            type="number"
                            inputLabel='Number of questions'
                            inputValue={numQuestions}
                            onChange={(e) => setNumQuestions(e.target.value)}
                            placeholder="Number of questions"
                            className="block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div className="mb-4">
                        <InputBox
                            required
                            type="number"
                            inputLabel='Total Marks'
                            inputValue={totalMarks}
                            onChange={(e) => setTotalMarks(e.target.value)}
                            placeholder="Total Marks"
                            className="block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>





                    <div className="mb-4">
                        <InputBox
                            required
                            type="text"
                            inputValue={timeToAttempt}
                            inputLabel='Time to attempt (in minutes)'
                            onChange={(e) => setTimeToAttempt(e.target.value)}
                            placeholder="Time to attempt (in minutes)"
                            className="block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div className="mb-4">


                        <CustomSelect
                            value={assessmentType}
                            onChange={(e) => setAssessmentType(e.target.value)}
                            options={assessmentTypeData}
                            dataField='value'
                            dataText='label'
                            label='Assessment Type'

                        />
                        {assessmentType === 'set' && (
                            <div className="mt-2">
                                <input
                                    required
                                    type="text"
                                    value={inputSet}
                                    onChange={(e) => setInputSet(e.target.value)}
                                    placeholder="Input set (if applicable)"
                                    className="block w-full p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                        )}
                    </div>

                    <div className="mb-4">


                        <CustomSelect
                            value={orderOfQuestions}
                            onChange={(e) => setOrderOfQuestions(e.target.value)}
                            options={assessmentOrderofQuestionData}
                            dataField='value'
                            dataText='label'
                            label='Order Of Question'



                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Difficulty</label>
                        <div className='w-1/3 m-2'>
                            <Slider
                                aria-label="Difficulty"
                                value={difficulty}
                                onChange={(e, newValue) => setDifficulty(newValue)}
                                getAriaValueText={valuetext}
                                step={null}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                    </div>





                    {apiResStatus && (
                        <div className='flex  justify-center align-middle gap-2'>
                            <p className="text-red-700 mb-4 ">{apiResStatus}</p>
                            {assessmentId && <div> <button className='border-none shadow-none underline text-sky-500' onClick={previewQuestions}>Preview Assessment</button></div>

                            }                        </div>
                    )}

                    <div className="flex justify-between space-x-4">
                        <Button
                            type="reset"
                            variant='outlined'
                        >
                            Reset
                        </Button>
                        <Button
                            type="submit"
                            disabled={loading}
                            variant='contained'
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default EditAssessment;
