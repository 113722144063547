import React from 'react';
import OptionsList from './OptionsList';
import AnswerList from './AnswerList';

const QuestionCard = ({ question, index }) => {
    const isMultipleCorrect = question.questionType === 'mcq-multiple';
    console.log(question)
    return (
        <div className="card p-4 border rounded shadow-sm">

            <h3 className="font-bold">Question {index + 1}:</h3>
            {question.questionType === 'free' ? (
                <p>
                    {question.question.startsWith('uploads\\') ? (
                        <img src={question.question} alt="Question" />
                    ) : (
                        question.question
                    )}
                </p>
            ) : (
                <p>{question.question}</p>
            )}
            {question.questionType.includes('mcq') && (
                <OptionsList options={question.optionDetails} isMultipleCorrect={isMultipleCorrect} />
            )}
            {question.questionType === 'free' && (
                <AnswerList answers={question.FreeAnswerDetails} />
            )}
            <p className="mt-2">
                <strong>Difficulty:</strong> {question.difficulty || 'Not specified'}
            </p>
        </div>
    );
};

export default QuestionCard;
