const sampleData = [
    { id: 1, name: 'Arjun Patel', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 6 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'MMG', experienceYears: 5, publications: 12, gender: 'Male', profileImage: 'm1' },
    { id: 2, name: 'Priya Sharma', lab: 'DRL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 8 }], score: 9, areaOfInterest: 'Training', experienceYears: 8, publications: 5, gender: 'Female', profileImage: 'f2' },
    { id: 3, name: 'Ravi Kumar', lab: 'SSPL', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 6 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Technical Coordination', experienceYears: 4, publications: 7, gender: 'Male', profileImage: 'm3' },
    { id: 4, name: 'Anjali Singh', lab: 'INMAS', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 6 }], score: 7, areaOfInterest: 'Publication/Research', experienceYears: 6, publications: 10, gender: 'Female', profileImage: 'f1' },
    { id: 5, name: 'Vikram Rao', lab: 'DIPAS', rank: 'Sc.  F', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 9, areaOfInterest: 'Rajbhasha', experienceYears: 3, publications: 4, gender: 'Male', profileImage: 'm4' },
    { id: 6, name: 'Aarti Desai', lab: 'DIPR', rank: 'Sc.  B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'MMG', experienceYears: 7, publications: 15, gender: 'Female', profileImage: 'f3' },
    { id: 7, name: 'Rakesh Jain', lab: 'DRL', rank: 'Sc.  D', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 9 }], score: 6, areaOfInterest: 'Training', experienceYears: 9, publications: 8, gender: 'Male', profileImage: 'm2' },
    { id: 8, name: 'Meera Patel', lab: 'SSPL', rank: 'Sc.  E', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Technical Coordination', experienceYears: 5, publications: 6, gender: 'Female', profileImage: 'f4' },
    { id: 9, name: 'Amit Verma', lab: 'INMAS', rank: 'Sc.  A', competencies: [{ competency: 'Leadership', score: 10 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 10 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 10 }], score: 10, areaOfInterest: 'MMG', experienceYears: 10, publications: 20, gender: 'Male', profileImage: 'm1' },
    { id: 10, name: 'Neha Gupta', lab: 'DIPAS', rank: 'Sc.  C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Publication/Research', experienceYears: 4, publications: 9, gender: 'Female', profileImage: 'f2' },
    { id: 11, name: 'Suresh Nair', lab: 'DIPR', rank: 'Sc.  D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Rajbhasha', experienceYears: 6, publications: 11, gender: 'Male', profileImage: 'm3' },
    { id: 12, name: 'Pooja Reddy', lab: 'DRL', rank: 'Sc.  C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Training', experienceYears: 7, publications: 8, gender: 'Female', profileImage: 'f1' },
    { id: 13, name: 'Ritu Sinha', lab: 'SSPL', rank: 'Sc.  E', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 9, areaOfInterest: 'Technical Coordination', experienceYears: 5, publications: 13, gender: 'Female', profileImage: 'f4' },
    { id: 14, name: 'Nikhil Rao', lab: 'INMAS', rank: 'Sc.  B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 8, areaOfInterest: 'MMG', experienceYears: 8, publications: 12, gender: 'Male', profileImage: 'm2' },
    { id: 15, name: 'Kavita Sharma', lab: 'DIPAS', rank: 'Sc.  D', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Publication/Research', experienceYears: 4, publications: 9, gender: 'Female', profileImage: 'f3' },
    { id: 16, name: 'Anil Mehta', lab: 'DIPR', rank: 'Sc.  E', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 7 }], score: 6, areaOfInterest: 'Rajbhasha', experienceYears: 9, publications: 6, gender: 'Male', profileImage: 'm4' },
    { id: 17, name: 'Sonia Kapoor', lab: 'DRL', rank: 'Sc.  B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 7 }], score: 8, areaOfInterest: 'MMG', experienceYears: 7, publications: 10, gender: 'Female', profileImage: 'f2' },
    { id: 18, name: 'Rajesh Kumar', lab: 'SSPL', rank: 'Sc.  F', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 9 }], score: 9, areaOfInterest: 'Training', experienceYears: 6, publications: 15, gender: 'Male', profileImage: 'm1' },
    { id: 19, name: 'Ayesha Khan', lab: 'INMAS', rank: 'Sc.  C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Technical Coordination', experienceYears: 5, publications: 8, gender: 'Female', profileImage: 'f4' },
    { id: 20, name: 'Suresh Choudhury', lab: 'DIPAS', rank: 'Sc.  B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Rajbhasha', experienceYears: 6, publications: 11, gender: 'Male', profileImage: 'm3' },
    { id: 21, name: 'Sneha Patil', lab: 'DIPR', rank: 'Sc.  C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'MMG', experienceYears: 4, publications: 5, gender: 'Female', profileImage: 'f1' },
    { id: 22, name: 'Ravi Deshmukh', lab: 'DRL', rank: 'Sc.  D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Training', experienceYears: 7, publications: 10, gender: 'Male', profileImage: 'm4' },
    { id: 23, name: 'Aditi Yadav', lab: 'SSPL', rank: 'Sc.  E', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 6 }, { competency: 'Innovation', score: 6 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 6 }], score: 6, areaOfInterest: 'Publication/Research', experienceYears: 8, publications: 8, gender: 'Female', profileImage: 'f3' },
    { id: 24, name: 'Karan Sharma', lab: 'INMAS', rank: 'Sc.  B', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 9, areaOfInterest: 'Rajbhasha', experienceYears: 7, publications: 14, gender: 'Male', profileImage: 'm2' },
    { id: 25, name: 'Rina Gupta', lab: 'DIPAS', rank: 'Sc.  C', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'MMG', experienceYears: 5, publications: 7, gender: 'Female', profileImage: 'f4' },
    { id: 26, name: 'Sanjay Kumar', lab: 'DIPR', rank: 'Sc.  B', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Systems Biology', experienceYears: 6, publications: 10, gender: 'Male', profileImage: 'm1' },
    { id: 27, name: 'Neha Mehta', lab: 'DRL', rank: 'Sc.  D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Training', experienceYears: 5, publications: 12, gender: 'Female', profileImage: 'f2' },
    { id: 28, name: 'Raj Patel', lab: 'SSPL', rank: 'Sc.  E', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'MMG', experienceYears: 7, publications: 8, gender: 'Male', profileImage: 'm4' },
    { id: 29, name: 'Isha Sinha', lab: 'INMAS', rank: 'Sc.  B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 7 }], score: 8, areaOfInterest: 'Training', experienceYears: 6, publications: 11, gender: 'Female', profileImage: 'f1' },
    { id: 30, name: 'Nikhil Sharma', lab: 'DIPAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 8, areaOfInterest: 'Training', experienceYears: 6, publications: 7, gender: 'Male', profileImage: 'm2' },
    { id: 31, name: 'Jaya Desai', lab: 'DIPR', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Rajbhasha', experienceYears: 5, publications: 9, gender: 'Female', profileImage: 'f4' },
    { id: 32, name: 'Arun Kumar', lab: 'DRL', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'MMG', experienceYears: 7, publications: 11, gender: 'Male', profileImage: 'm3' },
    { id: 33, name: 'Maya Verma', lab: 'SSPL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 7 }], score: 6, areaOfInterest: 'Technical Coordination', experienceYears: 8, publications: 7, gender: 'Female', profileImage: 'f2' },
    { id: 34, name: 'Shankar Patel', lab: 'INMAS', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Publication/Research', experienceYears: 6, publications: 12, gender: 'Male', profileImage: 'm4' },
    { id: 35, name: 'Nisha Sharma', lab: 'DIPAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'MMG', experienceYears: 5, publications: 6, gender: 'Female', profileImage: 'f3' },
    { id: 36, name: 'Amit Kumar', lab: 'DIPR', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'Rajbhasha', experienceYears: 5, publications: 8, gender: 'Male', profileImage: 'm2' },
    { id: 37, name: 'Ritu Singh', lab: 'DRL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 6 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 6 }], score: 7, areaOfInterest: 'Training', experienceYears: 6, publications: 8, gender: 'Female', profileImage: 'f1' },
    { id: 38, name: 'Sandeep Yadav', lab: 'SSPL', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 7 }], score: 8, areaOfInterest: 'Technical Coordination', experienceYears: 6, publications: 9, gender: 'Male', profileImage: 'm4' },
    { id: 39, name: 'Poonam Gupta', lab: 'INMAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'MMG', experienceYears: 4, publications: 6, gender: 'Female', profileImage: 'f3' },
    { id: 40, name: 'Sunil Patel', lab: 'DIPAS', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 9 }], score: 9, areaOfInterest: 'Rajbhasha', experienceYears: 7, publications: 12, gender: 'Male', profileImage: 'm1' },
    { id: 41, name: 'Sonia Mehta', lab: 'DIPR', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'MMG', experienceYears: 5, publications: 7, gender: 'Female', profileImage: 'f4' },
    { id: 42, name: 'Ajay Kumar', lab: 'DRL', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Technical Coordination', experienceYears: 6, publications: 11, gender: 'Male', profileImage: 'm2' },
    { id: 43, name: 'Meenal Yadav', lab: 'SSPL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Publication/Research', experienceYears: 4, publications: 6, gender: 'Female', profileImage: 'f2' },
    { id: 44, name: 'Amit Gupta', lab: 'INMAS', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 9 }], score: 9, areaOfInterest: 'Training', experienceYears: 8, publications: 13, gender: 'Male', profileImage: 'm4' },
    { id: 45, name: 'Rita Sharma', lab: 'DIPAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'MMG', experienceYears: 5, publications: 9, gender: 'Female', profileImage: 'f1' },
    { id: 46, name: 'Vikram Sharma', lab: 'DIPR', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Rajbhasha', experienceYears: 6, publications: 8, gender: 'Male', profileImage: 'm3' },
    { id: 47, name: 'Priti Agarwal', lab: 'DRL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 6 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Technical Coordination', experienceYears: 5, publications: 7, gender: 'Female', profileImage: 'f4' },
    { id: 48, name: 'Kunal Patel', lab: 'SSPL', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'Training', experienceYears: 7, publications: 11, gender: 'Male', profileImage: 'm2' },
    { id: 49, name: 'Sonali Sinha', lab: 'INMAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'Training', experienceYears: 7, publications: 11, gender: 'Male', profileImage: 'f4' },

    { id: 62, name: 'Sandeep Joshi', lab: 'DIPAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'MMG', experienceYears: 5, publications: 9, gender: 'Male', profileImage: 'm2' },
    { id: 63, name: 'Madhuri Rao', lab: 'DIPR', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Rajbhasha', experienceYears: 6, publications: 12, gender: 'Female', profileImage: 'f3' },
    { id: 64, name: 'Kunal Mehta', lab: 'DRL', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Training', experienceYears: 5, publications: 7, gender: 'Male', profileImage: 'm1' },
    { id: 65, name: 'Neha Arora', lab: 'SSPL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Technical Coordination', experienceYears: 5, publications: 11, gender: 'Female', profileImage: 'f2' },
    { id: 66, name: 'Amit Singh', lab: 'INMAS', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 7 }], score: 8, areaOfInterest: 'Rajbhasha', experienceYears: 6, publications: 10, gender: 'Male', profileImage: 'm4' },
    { id: 67, name: 'Sonia Kapoor', lab: 'DIPAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'MMG', experienceYears: 6, publications: 11, gender: 'Female', profileImage: 'f4' },
    { id: 68, name: 'Rohan Joshi', lab: 'DIPR', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Publication/Research', experienceYears: 5, publications: 9, gender: 'Male', profileImage: 'm3' },
    { id: 69, name: 'Gita Nair', lab: 'DRL', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Training', experienceYears: 7, publications: 9, gender: 'Female', profileImage: 'f1' },
    { id: 70, name: 'Vikram Patil', lab: 'SSPL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 8, areaOfInterest: 'MMG', experienceYears: 6, publications: 10, gender: 'Male', profileImage: 'm2' },
    { id: 71, name: 'Simran Kaur', lab: 'INMAS', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 9 }], score: 9, areaOfInterest: 'Rajbhasha', experienceYears: 7, publications: 12, gender: 'Female', profileImage: 'f3' },
    { id: 72, name: 'Arvind Kumar', lab: 'DIPAS', rank: 'Sc. A', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Technical Coordination', experienceYears: 8, publications: 14, gender: 'Male', profileImage: 'm4' },
    { id: 73, name: 'Ayesha Ali', lab: 'DIPR', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'MMG', experienceYears: 5, publications: 8, gender: 'Female', profileImage: 'f2' },
    { id: 74, name: 'Rajesh Reddy', lab: 'DRL', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Training', experienceYears: 6, publications: 10, gender: 'Male', profileImage: 'm1' },
    { id: 75, name: 'Meera Singh', lab: 'SSPL', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Technical Coordination', experienceYears: 6, publications: 9, gender: 'Female', profileImage: 'f4' },
    { id: 76, name: 'Deepak Sharma', lab: 'INMAS', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 6 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Rajbhasha', experienceYears: 5, publications: 8, gender: 'Male', profileImage: 'm2' },
    { id: 77, name: 'Nisha Patel', lab: 'DIPAS', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Publication/Research', experienceYears: 5, publications: 10, gender: 'Female', profileImage: 'f1' },
    { id: 78, name: 'Pankaj Agarwal', lab: 'DIPR', rank: 'Sc. A', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 9, areaOfInterest: 'MMG', experienceYears: 8, publications: 15, gender: 'Male', profileImage: 'm4' },
    { id: 79, name: 'Kritika Bhatia', lab: 'DRL', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Rajbhasha', experienceYears: 6, publications: 10, gender: 'Female', profileImage: 'f3' },
    { id: 80, name: 'Ritesh Jain', lab: 'SSPL', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Publication/Research', experienceYears: 5, publications: 8, gender: 'Male', profileImage: 'm1' },
    { id: 81, name: 'Suman Sharma', lab: 'INMAS', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Technical Coordination', experienceYears: 6, publications: 9, gender: 'Female', profileImage: 'f2' },
    { id: 82, name: 'Anil Reddy', lab: 'DIPAS', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 6 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Rajbhasha', experienceYears: 5, publications: 7, gender: 'Male', profileImage: 'm4' },
    { id: 83, name: 'Divya Nair', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'MMG', experienceYears: 6, publications: 11, gender: 'Female', profileImage: 'f1' },
    { id: 84, name: 'Tushar Gupta', lab: 'DRL', rank: 'Sc. A', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 8 }], score: 9, areaOfInterest: 'Publication/Research', experienceYears: 8, publications: 15, gender: 'Male', profileImage: 'm2' },
    { id: 85, name: 'Kanika Sethi', lab: 'SSPL', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'Technical Coordination', experienceYears: 6, publications: 10, gender: 'Female', profileImage: 'f3' },
    { id: 86, name: 'Harsh Patel', lab: 'INMAS', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Rajbhasha', experienceYears: 5, publications: 8, gender: 'Male', profileImage: 'm1' },
    { id: 87, name: 'Poonam Sharma', lab: 'DIPAS', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Publication/Research', experienceYears: 5, publications: 9, gender: 'Female', profileImage: 'f2' },
    { id: 88, name: 'Aakash Rao', lab: 'DIPR', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'MMG', experienceYears: 7, publications: 11, gender: 'Male', profileImage: 'm3' },
    { id: 89, name: 'Snehal Patel', lab: 'DRL', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Training', experienceYears: 6, publications: 8, gender: 'Female', profileImage: 'f4' },
    { id: 90, name: 'Nitin Agarwal', lab: 'SSPL', rank: 'Sc. A', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Rajbhasha', experienceYears: 7, publications: 14, gender: 'Male', profileImage: 'm2' },
    { id: 91, name: 'Ruchi Mehta', lab: 'INMAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Publication/Research', experienceYears: 5, publications: 10, gender: 'Female', profileImage: 'f1' },
    { id: 92, name: 'Kiran Desai', lab: 'DIPAS', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Technical Coordination', experienceYears: 6, publications: 8, gender: 'Male', profileImage: 'm4' },
    { id: 93, name: 'Aarti Kapoor', lab: 'DIPR', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 6 }], score: 6, areaOfInterest: 'MMG', experienceYears: 5, publications: 7, gender: 'Female', profileImage: 'f2' },
    { id: 94, name: 'Arun Kumar', lab: 'DRL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 6 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 7, areaOfInterest: 'Rajbhasha', experienceYears: 5, publications: 8, gender: 'Male', profileImage: 'm1' },
    { id: 95, name: 'Meenal Singh', lab: 'SSPL', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'Publication/Research', experienceYears: 6, publications: 9, gender: 'Female', profileImage: 'f3' },
    { id: 96, name: 'Manoj Sharma', lab: 'INMAS', rank: 'Sc. A', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Technical Coordination', experienceYears: 7, publications: 12, gender: 'Male', profileImage: 'm4' },
    { id: 97, name: 'Anjali Bhatia', lab: 'DIPAS', rank: 'Sc. B', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Rajbhasha', experienceYears: 6, publications: 11, gender: 'Female', profileImage: 'f1' },
    { id: 98, name: 'Vishal Patel', lab: 'DIPR', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Publication/Research', experienceYears: 5, publications: 9, gender: 'Male', profileImage: 'm2' },
    { id: 99, name: 'Swati Verma', lab: 'DRL', rank: 'Sc. D', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 8, areaOfInterest: 'Technical Coordination', experienceYears: 6, publications: 10, gender: 'Female', profileImage: 'f4' },
    { id: 100, name: 'Ravi Kumar', lab: 'SSPL', rank: 'Sc. E', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 7 }], score: 7, areaOfInterest: 'Training', experienceYears: 6, publications: 8, gender: 'Male', profileImage: 'm3' }
    ,

    { id: 101, name: 'jai Patel', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 6 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 8, areaOfInterest: 'MMG', experienceYears: 5, publications: 12, gender: 'Male', profileImage: 'm1' },

    { id: 102, name: 'Aarav Singh', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 8 }], score: 7.2, areaOfInterest: 'Cybersecurity', experienceYears: 4, publications: 8, gender: 'Male', profileImage: 'm2' },

    { id: 103, name: 'Maya Rao', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 5 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 7.8, areaOfInterest: 'MMG', experienceYears: 6, publications: 15, gender: 'Female', profileImage: 'f4' },

    { id: 104, name: 'Ravi Iyer', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 5 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 6 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7.0, areaOfInterest: 'Energy', experienceYears: 3, publications: 9, gender: 'Male', profileImage: 'm3' },

    { id: 105, name: 'Meera Nair', lab: 'DIPR', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 9 }], score: 8.2, areaOfInterest: 'MMG', experienceYears: 7, publications: 18, gender: 'Female', profileImage: 'f3' },

    { id: 106, name: 'Raj Malhotra', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 5 }, { competency: 'Team Collaboration', score: 5 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 7.4, areaOfInterest: 'Astronomy', experienceYears: 10, publications: 20, gender: 'Male', profileImage: 'm4' },

    { id: 107, name: 'Priya Sharma', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 5 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 9 }], score: 8.6, areaOfInterest: 'MMG', experienceYears: 8, publications: 22, gender: 'Female', profileImage: 'f2' },

    { id: 108, name: 'Kiran Verma', lab: 'DIPR', rank: 'Sc. C', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 8 }], score: 7.6, areaOfInterest: 'MMG', experienceYears: 4, publications: 10, gender: 'Female', profileImage: 'f1' }
    ,
    { id: 109, name: 'Kunal sood', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 8 }], score: 7.2, areaOfInterest: 'MMG', experienceYears: 6, publications: 15, gender: 'Male', profileImage: 'm2' },

    { id: 110, name: 'Anjali Nair', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 7.8, areaOfInterest: 'MMG', experienceYears: 7, publications: 20, gender: 'Female', profileImage: 'f4' },

    { id: 112, name: 'Ravi Iyer', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 9 }, { competency: 'Innovation', score: 6 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 7 }], score: 7.0, areaOfInterest: 'MMG', experienceYears: 4, publications: 10, gender: 'Male', profileImage: 'm3' },

    { id: 113, name: 'Riya Kapoor', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 6 }, { competency: 'Communication', score: 9 }], score: 8.2, areaOfInterest: 'MMG', experienceYears: 8, publications: 18, gender: 'Female', profileImage: 'f3' },

    { id: 114, name: 'Nikhil Desai', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 6 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 8 }], score: 7.4, areaOfInterest: 'MMG', experienceYears: 9, publications: 22, gender: 'Male', profileImage: 'm4' },

    { id: 115, name: 'Priya Sharma', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 7 }, { competency: 'Innovation', score: 8 }, { competency: 'Project Management', score: 9 }, { competency: 'Communication', score: 9 }], score: 8.6, areaOfInterest: 'MMG', experienceYears: 10, publications: 24, gender: 'Female', profileImage: 'f2' },

    { id: 116, name: 'Rohit Malhotra', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 7 }, { competency: 'Team Collaboration', score: 6 }, { competency: 'Innovation', score: 9 }, { competency: 'Project Management', score: 8 }, { competency: 'Communication', score: 9 }], score: 7.8, areaOfInterest: 'MMG', experienceYears: 6, publications: 14, gender: 'Male', profileImage: 'm1' },

    { id: 117, name: 'Swati Deshmukh', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 9 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 6 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 8 }], score: 7.6, areaOfInterest: 'MMG', experienceYears: 7, publications: 16, gender: 'Female', profileImage: 'f4' },

    { id: 118, name: 'Vivek Kapoor', lab: 'DIPR', rank: 'Sc. F', competencies: [{ competency: 'Leadership', score: 8 }, { competency: 'Team Collaboration', score: 8 }, { competency: 'Innovation', score: 7 }, { competency: 'Project Management', score: 7 }, { competency: 'Communication', score: 9 }], score: 7.8, areaOfInterest: 'MMG', experienceYears: 12, publications: 25, gender: 'Male', profileImage: 'm2' }

]
export default sampleData