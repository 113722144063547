import React from 'react'

function Unauthorised() {
    return (
        <div className='flex-col justify-center items-center h-screen'>
            <h1>Unauthorized</h1>
            <p>You do not have permission to view this page.</p>
        </div>
    )
}

export default Unauthorised
