import React, { act, useEffect, useState } from 'react';
import './App.css';
import AddCandidateModal from './AddjobroleModal';
import EditCandidateModal from './EditjobroleModal';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { AddIconButton, DeleteIconButton, EditIconButton, ManageIconButton, NormalmuiContainedButtom, SearchIconButton } from '../../ButtonsIconsInputs/IconButtons';
import TableComp from '../reusableComp/TableComp';
import Heading from '../reusableComp/Heading';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import { hasPermission, filterActionsByPermission } from '../reusableComp/PermissionCheck';

function JobRole() {

    const permissionsNameForThisPage = {
        Add: 'Jobrole-Add',
        Edit: 'Jobrole-Edit',
        Delete: 'Jobrole-Delete',
        Manage: 'Jobrole-ManageCompetency',

    }
    const [jobrole, setjobroleData] = useState([]);
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [recentData, setrecentData] = useState('');
    const [recenteditData, setrecenteditData] = useState('');
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentCandidate, setcurrentCandidate] = useState(null);
    const [permittedActions, setpermittedActions] = useState()
    const api_url = process.env.REACT_APP_API_URL
    const navigate = useNavigate()


    // const token = localStorage.getItem('token')
    // const decodedtoken = jwtDecode(token)
    // const permissions = decodedtoken.permissions

    // const hasPermission = (permissions, requiredPermission) => {
    //     return permissions.some(permission =>
    //         permission.trim().toLowerCase() === requiredPermission.trim().toLowerCase()
    //     );
    // };




    useEffect(() => {
        if (insertionStatus) {
            getjobroleDetails()
            setInsertionStatus(false);
        }
    }, [insertionStatus, recentData, jobrole]);

    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }
    const handleEditOpen = (candidate, index) => {
        setcurrentCandidate(candidate); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrentCandidate(null); }

    const getjobroleDetails = async () => {
        let result = await fetch(`${api_url}/jobroles`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
        });
        result = await result.json();
        console.log(result);
        setjobroleData(result.data);
        setfilteredData(result.data);
        console.log(jobrole)
    }

    useEffect(() => {
        getjobroleDetails();
    }, []);

    const handleDelete = async (item) => {
        let jobroleId = Object.values(item)[0]

        const res = await fetch(`${api_url}/jobroles/delete/${jobroleId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': "application/json" },
        });
        if (res.ok) {
            console.log("deleted successfully");
            getjobroleDetails();
        } else {
            console.log("error occurred");
        }
    }

    const handleSearch = (e) => {
        console.log(query);
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(jobrole);
        } else {
            const result = jobrole.filter(jobrole => jobrole.jobroleName.toLowerCase().includes(searchQuery));
            setfilteredData(result);
        }
    }

    useEffect(() => {
        console.log("change")
        if (editStatus) {
            getjobroleDetails()
            setEditStatus(false)
        }
    }, [editStatus]);

    const Openjobrolecompetency = (currentjobrole) => {
        navigate('/jobrole_competency', { state: { currentjobrole, jobrole } })


    }

    const columns = [
        { header: 'Name', field: 'jobroleName' },
        { header: 'Description', field: 'jobroleDescription' }
    ];

    const actions = [
        {
            label: 'Edit',
            handler: handleEditOpen,
            tooltip: 'Edit item',
            ButtonComponent: EditIconButton, // Use EditIconButton for edit action
            requiredPermission: permissionsNameForThisPage.Edit
        },
        {
            label: 'Delete',
            handler: handleDelete,
            tooltip: 'Delete item',

            ButtonComponent: DeleteConfirmation, // Use DeleteIconButton for delete action
            requiredPermission: permissionsNameForThisPage.Edit

        },
        {
            label: 'Manage Competency',
            handler: (item) => Openjobrolecompetency(item),
            className: '',
            ButtonComponent: ManageIconButton,
            tooltip: 'manage competencies',
            requiredPermission: permissionsNameForThisPage.Manage



        }
    ];


    const AllowedActions = filterActionsByPermission(actions);


    return (
        <div className="container mx-auto py-8">
            <Heading heading={"Manage Job Roles"} />

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='searchJobrole'
                        inputLabel="Search Jobrole"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>
                {hasPermission(permissionsNameForThisPage.Add) && <div>

                    <AddIconButton action={handleopen} id={"addjobrole"} tooltip='Add new role' />
                    {open && <AddCandidateModal handleclose={handleclose} setStatus={setInsertionStatus} setRecentformData={setrecentData} />}
                </div>
                }
            </div>

            <TableComp
                data={filteredData}
                columns={columns}
                actions={AllowedActions}
            />
            {editOpen && (
                <EditCandidateModal
                    Data={currentCandidate}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus}
                    setRecenteditFormData={setrecenteditData}
                />
            )}
        </div>
    );
}

export default JobRole;
