import React from 'react';

const PageNotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
            <h1 className="text-6xl font-bold text-red-600 mb-4">404</h1>
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Page Not Found</h2>
            <p className="text-gray-600 text-center max-w-md">
                The page you are looking for does not exist. It might have been moved or deleted.
            </p>

        </div>
    );
};

export default PageNotFound;
